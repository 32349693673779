import React, { Component } from "react";
class Compose extends Component {
  state = {
    message: {
      receiver: null,
      subject: null,
      msg: null,
    },
  };
  setInput = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      message: {
        ...prevState.message,
        [name]: value,
      },
    }));
  };
  waitingBTN = (id) => {
    const bt = document.getElementById(id);
    bt.classList.add("disable-link");
    bt.innerHTML = "wait...";
  };
  unWaitingBTN = (id, o) => {
    const bt = document.getElementById(id);
    bt.classList.remove("disable-link");
    bt.innerHTML = o;
  };
  error = (m) => {
    const e = document.getElementById("err");
    e.innerHTML = m;
  };
  suc = (m) => {
    const s = document.getElementById("suc");
    s.innerHTML = m;
  };
  refreshForm = () => {
    const receiver = document.getElementById("receiver");
    const subject = document.getElementById("subject");
    const msg = document.getElementById("msg");
    receiver.selectedIndex = 0;
    subject.value = "";
    msg.value = "";
  };
  errorForm = (id) => {
    const form = document.getElementById(id);
    form.classList.remove("border-success");
    form.classList.add("border-danger");
  };
  UnerrorForm = (id) => {
    const form = document.getElementById(id);
    form.classList.remove("border-danger");
    form.classList.add("border-success");
  };
  handleSendMessage = async () => {
    this.waitingBTN("btn");
    this.error("");
    this.suc("");
    this.UnerrorForm("receiver");
    this.UnerrorForm("subject");
    this.UnerrorForm("msg");
    if (this.state.message.receiver === null) {
      this.errorForm("receiver");
      // this.error("You should select one of receiver option");
      this.unWaitingBTN("btn", "Send");
    } else {
      if (this.state.message.subject === null) {
        this.errorForm("subject");
        // this.error("You should enter subject of your message");
        this.unWaitingBTN("btn", "Send");
      } else {
        if (this.state.message.msg === null) {
          this.errorForm("msg");
          // this.error("Your message can't be empty");
          this.unWaitingBTN("btn", "Send");
        } else {
          const res = await this.sendMessage(
            this.props.wallet,
            this.state.message.receiver,
            this.state.message.subject,
            this.state.message.msg
          );
          if (res) {
            this.unWaitingBTN("btn", "Send");
            this.suc("Your message sent succesfully");
            this.refreshForm();
          } else {
            this.unWaitingBTN("btn", "Send");
            this.error("Something wrong, try again later");
          }
        }
      }
    }
  };
  sendMessage = async (w, r, s, msg) => {
    const get = await fetch("https://api.the369.xyz/sendMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        receiver: r,
        subject: s,
        message: msg,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh-btn").click();
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  render() {
    return (
      <>
        <button
          id="refresh-btn"
          className="d-none"
          onClick={this.props.refreshMessages}
        ></button>
        <div className="col-12 col-md-10 px-2 h-msg">
          <div className="row align-self-center g-1 w-100 h-100">
            <div className="col-12 border border-1 BR-5 mt-2 p-0 border-white-05 h-100">
              <div className="row align-self-center g-1 w-100 p-0 h-100">
                {this.props.user.AllowToMessage === 1 ? (
                  <>
                    <div className="col-12 h-100 d-flex flex-column p-3">
                      <select
                        id="receiver"
                        className="form-select form-select-sm  mt-5 ms-2 max-w-300 bg-dark text-muted border-success"
                        aria-label="Default select example"
                        onChange={this.setInput}
                        name="receiver"
                        defaultValue="none"
                      >
                        <option value="none">To: </option>
                        {this.props.user.messageTo
                          .split("-")
                          .map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                      </select>
                      <input
                        id="subject"
                        className="form-control form-control-sm m-2 max-w-300 border-success bg-dark text-white "
                        type="text"
                        placeholder="subject"
                        onChange={this.setInput}
                        name="subject"
                      />
                      <textarea
                        type="text"
                        className=" form-control form-control-xl bg-dark text-white border-success form-signup ms-2"
                        id="msg"
                        onChange={this.setInput}
                        name="msg"
                        placeholder="Message..."
                        required
                      ></textarea>
                      <span className="fs-6 text-success" id="suc"></span>
                      <span className="fs-6 text-danger" id="err"></span>
                      <button
                        id="btn"
                        onClick={this.handleSendMessage}
                        className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 mt-auto mb-5"
                      >
                        Send
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12 h-100 d-flex flex-column p-3">
                      <select
                        id="receiver"
                        className="form-select form-select-sm  mt-5 ms-2 max-w-300 bg-dark text-muted border-danger"
                        aria-label="Default select example"
                        onChange={this.setInput}
                        name="receiver"
                        defaultValue="none"
                        disabled
                      >
                        <option value="none">To: </option>
                        {this.props.user.messageTo
                          .split("-")
                          .map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                      </select>
                      <input
                        id="subject"
                        className="form-control form-control-sm m-2 max-w-300 border-danger bg-dark text-white "
                        type="text"
                        placeholder="subject"
                        name="subject"
                        disabled
                      />
                      <textarea
                        type="text"
                        className=" form-control form-control-xl bg-dark text-white border-danger form-signup ms-2"
                        id="msg"
                        name="msg"
                        placeholder="Message..."
                        required
                        disabled
                      ></textarea>
                      <span className="fs-6 text-success" id="suc"></span>
                      <span className="fs-6 text-danger" id="err"></span>
                      <button
                        id="btn"
                        className="btn-s bg-dark align-self-start ms-3 mt-3 fs-6 mt-auto mb-5 disable-link text-muted"
                        disabled
                      >
                        Disabled
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Compose;
