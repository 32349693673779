import React, { Component } from "react";

class NotifItem extends Component {
  state = {};
  timeGenarator = (t) => {
    // eslint-disable-next-line
    const [date, time] = t.split("T");
    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    // const [H, M, S] = time.split(":");
    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  render() {
    return (
      <>
        <div
          key={this.props.k}
          className="col-12  d-flex justify-content-start  align-self-center"
        >
          <div className="notification bg-dark d-flex flex-column ms-4 w-100 position-relative">
            {/* <span className="fs-7 fw-bold text-success position-absolute top-0 end-0 me-2 mt-2">
              <i className="bi bi-bell fs-4 text-warning"></i>
            </span> */}

            <span className="fs-8 fw-bold px-1 mt-2 position-absolute top-0 end-0 me-2 mb-2">
              <i className="bi bi-bell fs-4 text-warning"></i>
            </span>
            <span className="fw-bolder fs-3">
              {this.props.item.subject}{" "}
              <span className="fs-8 text-muted ms-2">
                {this.timeGenarator(this.props.item.Time)}
              </span>
            </span>
            <span className="my-2">{this.props.item.message}</span>
            {this.props.item.xp !== 0 || this.props.item.score !== 0 ? (
              <>
                <span className="reward-notif d-flex justify-content-between">
                  <span className="fw-bolder align-self-center">Rewards:</span>{" "}
                  {this.props.item.xp !== 0 ? (
                    <>
                      <span className="ms-2 p-1 bg-dark rounded">
                        {this.props.item.xp}{" "}
                        <img
                          alt="AKU XP"
                          className=""
                          width="20"
                          height="20"
                          src="./asset/Menu/xp.png"
                        />{" "}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.props.item.score !== 0 ? (
                    <>
                      {this.props.item.score === 1 ? (
                        <>
                          <span className="ms-2 p-1 bg-dark rounded text-success fw-bold fs-7">
                            {this.props.item.score} POINT
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="ms-2 p-1 bg-dark rounded text-success fw-bold fs-7">
                            {this.props.item.score} POINTS
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default NotifItem;
