import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Get6Keys from "../Get6Keys";
import SpecialOneItem from "./SpecialOneItem";
var md5 = require("md5");
class SpecialOne extends Component {
  state = {
    code: null,
    show: false,
    s: {
      id: 1,
      subject: "MISSION #1",
      message: "",
      guide: "THIS IS GUIDE",
      SUBMISSION: "THIS IS SUBMISSION",
      answer: "ac96823403192d1fbdfe1deef7f877d6",
      reward: { xp: 18, point: 3 },
      requirements: { score: 0 },
    },
  };
  waitingBTN = (id) => {
    const bt = document.getElementById(id);
    bt.classList.add("disable-link");
    bt.innerHTML = "wait...";
  };
  unWaitingBTN = (id, o) => {
    const bt = document.getElementById(id);
    bt.classList.remove("disable-link");
    bt.innerHTML = o;
  };
  error = (m) => {
    const e = document.getElementById("err");
    e.innerHTML = m;
  };
  suc = (m) => {
    const s = document.getElementById("suc");
    s.innerHTML = m;
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  handleOpen = () => {
    this.setState({ show: true });
  };
  handleForm = () => {
    document.getElementById("vid").classList.add("d-none");
    document.getElementById("form").classList.remove("d-none");
  };
  handleComplete = () => {
    document.getElementById("vid").classList.add("d-none");
    document.getElementById("compelete").classList.remove("d-none");
  };
  handleFormToReward = () => {
    document.getElementById("form").classList.add("d-none");
    document.getElementById("open").classList.remove("d-none");
  };
  handleRewardToCompelete = () => {
    document.getElementById("open").classList.add("d-none");
    document.getElementById("compelete").classList.remove("d-none");
  };
  setInput = (e) => {
    // eslint-disable-next-line
    const { name, value } = e.target;

    this.setState((prevState) => ({
      code: value,
    }));
  };
  addScoreXp = async (w, x, s) => {
    const get = await fetch("https://api.the369.xyz/addScoreXp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        xp: x,
        score: s,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("update-btn").click();
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  insertMissionAnswer = async (name, id, w, a, x, s) => {
    const get = await fetch("https://api.the369.xyz/insertMissionAnswer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: name,
        mission_id: id,
        wallet_address: w,
        answer: a,
        xp: x,
        point: s,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  insertMissionLog = async (name, w, a) => {
    const get = await fetch("https://api.the369.xyz/insertMissionLog", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        mission_name: name,
        answer: a,
        wallet_address: w,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  reduceBonusKey = async (id) => {
    const get = await fetch("https://api.the369.xyz/reduceBonusKey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        user_id: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("update-btn").click();
    }
    if (get.status === "failed") {
    }
  };
  reduceBuyKey = async (id) => {
    const get = await fetch("https://api.the369.xyz/reduceBuyKey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        user_id: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("update-btn").click();
    }
    if (get.status === "failed") {
    }
  };
  reduceSoul = (id) => {
    if (this.props.user.bonus_key === 0) {
      this.reduceBuyKey(id);
    } else {
      this.reduceBonusKey(id);
    }
  };
  checkTheCode = async () => {
    this.error("");
    this.suc("");
    this.waitingBTN("btn");

    if (
      md5(this.state.code.toLowerCase().replaceAll(" ", "")) ===
      this.state.s.answer
    ) {
      const res = await this.insertMissionAnswer(
        this.state.s.subject,
        this.state.s.id,
        this.props.user.wallet_address,
        this.state.code,
        this.state.s.reward.xp,
        this.state.s.reward.point
      );
      if (res) {
        this.addScoreXp(
          this.props.user.wallet_address,
          this.state.s.reward.xp,
          this.state.s.reward.point
        );
        document.getElementById("refresh").click();
        document.getElementById("form").classList.add("d-none");
        document.getElementById("complete").classList.remove("d-none");
      } else {
        this.error("Something Wrong, try again later");
      }
    } else {
      this.insertMissionLog(
        this.state.s.subject,
        this.props.user.wallet_address,
        this.state.code
      );
      this.reduceSoul(this.props.user.user_id);
      this.error("Wrong key");
    }
    this.unWaitingBTN("btn", "CHECK");
  };
  warning = () => {
    this.error("Nice try, but the mission is completed");
  };

  render() {
    const key = this.props.user.buy_key + this.props.user.bonus_key;
    const pre_score = this.props.user.score > this.state.s.requirements.score;
    const lock = !pre_score;
    const status = this.props.missionPass.includes(this.state.s.id)
      ? "complete"
      : "undone";

    return (
      <>
        <button
          id="update-btn"
          className="d-none"
          onClick={this.props.updateUser}
        ></button>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshQuests}
        ></button>
        <div
          onClick={lock ? "" : this.handleOpen}
          className={
            lock
              ? "col-12 col-md-6 col-lg-4 col-xl-3 p-2  "
              : "col-12 col-md-6 col-lg-4 col-xl-3 p-2  c-pointer"
          }
        >
          <div className="d-flex flex-column justify-content-center align-self-center position-relative  border border-1  BR-5 p-2">
            <div className="position-relative">
              <img
                alt="AKUAKU"
                className={lock ? "lock-quest w-100" : " w-100"}
                src="./asset/special/One/1.png"
              />

              {lock ? (
                <>
                  <div class="position-absolute top-50 start-50 translate-middle mt-4">
                    <i class="bi bi-lock-fill fs-1 text-white "></i>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <span className=" text-center fs-5 fw-bolder mt-2">
              {this.state.s.subject}{" "}
              {status === "complete" ? (
                <>
                  <div className="text-success fs-7 fw-bolder">(COMPLETED)</div>
                </>
              ) : (
                <></>
              )}
            </span>
            <hr></hr>
            <span className=" text-start">Mission requirements:</span>
            <span className=" text-start fs-7">
              <i
                class={
                  pre_score
                    ? "bi bi-circle-fill me-2 text-success"
                    : "bi bi-circle-fill me-2 text-danger"
                }
              ></i>{" "}
              <span className=" text-muted">{"NONE"}</span>
            </span>
          </div>
        </div>

        <Modal
          show={this.state.show}
          onHide={() => {
            this.handleClose();
          }}
          // backdrop="static"
          keyboard={false}
          fullscreen={true}
          centered
          size="lg"
          contentClassName="bg-black"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative">
                {" "}
                {this.state.s.subject}{" "}
                <span className="position-absolute top-0 start-120 translate-middle  bg-light text-muted px-1 rounded-pill fs-8">
                  SPECIAL<span className="visually-hidden">SPECIAL</span>
                </span>
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row  g-1 w-100 g-2 ">
              <div
                id="compelete"
                className={
                  status === "complete"
                    ? "col-12 text-center  d-flex flex-column "
                    : "col-12 text-center d-none  d-flex flex-column "
                }
              >
                <span className="fs-3 fw-bolder text-center text-success">
                  MISSION COMPLETED
                </span>
                <span className="text-muted fs-5">
                  REWARD:{" "}
                  {this.state.s.reward.xp === 0
                    ? ""
                    : `${this.state.s.reward.xp} XP,`}{" "}
                  {"    "}{" "}
                  {this.state.s.reward.point === 0
                    ? ""
                    : this.state.s.reward.point > 1
                    ? `${this.state.s.reward.point} POINTS`
                    : `${this.state.s.reward.point} POINT`}
                </span>
              </div>

              <div id="vid" className="col-12  ">
                <SpecialOneItem />
              </div>
              <div
                id="form"
                className={
                  status === "complete"
                    ? "col-12 text-center d-none "
                    : "col-12 text-center "
                }
              >
                {this.state.s.message} <br />
                <div className="d-flex flex-column">
                  <span className="d-flex me-auto ms-auto">
                    <input
                      onChange={this.setInput}
                      name="code"
                      className={
                        key !== 0
                          ? "form-control form-control-lg m-2 w-300 border-success bg-dark text-white"
                          : "form-control form-control-lg m-2 w-300 border-danger disable-link bg-dark text-white"
                      }
                      type="text"
                      placeholder="Enter the Key"
                    />

                    <span
                      className={
                        key < 10
                          ? " text-center border-red  h-100 p-1 d-flex flex-column BR-5 pt-2 "
                          : " text-center border-green  h-100 p-1 d-flex flex-column BR-5 pt-2"
                      }
                    >
                      <img
                        alt="AKU XP"
                        className="mt-2"
                        width="20"
                        src="./asset/logo/soulbadge.png"
                      />
                      <span className="c-reg  fs-8">{key}</span>
                    </span>
                  </span>
                  <span className="fs-6 text-success" id="suc"></span>
                  <span className="fs-6 text-danger" id="err"></span>
                  {key === 0 ? (
                    <>
                      <button
                        id="btn"
                        className="btn-s bg-red mt-2 disable-link max-w-100 ms-auto me-auto"
                      >
                        Disable
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        id="btn"
                        onClick={
                          status === "complete"
                            ? this.warning
                            : this.checkTheCode
                        }
                        className="btn btn-success max-w-100 ms-auto me-auto"
                      >
                        CHECK
                      </button>
                    </>
                  )}
                  <Get6Keys
                    refreshQuests={this.props.refreshQuests}
                    updateUser={this.props.updateUser}
                    user={this.props.user}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default SpecialOne;
