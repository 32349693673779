import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class SentItem extends Component {
  state = {
    show: false,
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  openMessage = () => {
    this.setState({ show: true });
  };
  deleteMSG = async (id) => {
    let text = "Are you sure?";
    if (window.confirm(text) === true) {
      const get = await fetch("https://api.the369.xyz/deleteSentMessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          SM_ID: id,
        }),
      }).then((res) => res.json());
      if (get.status === "done") {
        document.getElementById("refresh-btn").click();
      }
      if (get.status === "failed") {
        alert("Something wrong");
      }
    } else {
    }
  };
  timeGenarator = (t) => {
    // eslint-disable-next-line
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    // const [H, M, S] = time.split(":");
    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  MessageBrief = (msg) => {
    var temp = "";
    let newMessage = msg.split("");

    let length = newMessage.length;

    if (length <= 15) {
      temp = newMessage;
    } else {
      for (let t = 0; t < 15; t++) {
        temp = temp + newMessage[t];
      }
      temp = temp + " ...";
    }

    return temp;
  };
  render() {
    return (
      <>
        <button
          id="refresh-btn"
          className="d-none"
          onClick={this.props.refreshMessages}
        ></button>

        <div className="col-12 border border-1 BR-5 mt-2 p-0 border-white-05 text-white">
          <div className="row align-self-center g-1 w-100 p-0 h-100">
            <div
              onClick={() => {
                this.openMessage();
              }}
              className="c-pointer col-3 msg-item h-100 d-flex align-items-center justify-content-center text-center"
            >
              <span>{this.props.message.receiver}</span>
            </div>
            <div
              onClick={() => {
                this.openMessage();
              }}
              className="c-pointer col-3 msg-item h-100 d-flex align-items-center justify-content-center text-center"
            >
              {this.props.message.subject}
            </div>
            <div
              onClick={() => {
                this.openMessage();
              }}
              className="c-pointer col-3 msg-item h-100 d-flex align-items-center justify-content-center text-center fs-7"
            >
              {this.MessageBrief(this.props.message.message)}
            </div>
            <div className="col-1 msg-item fs-8 h-100 d-flex align-items-center justify-content-center text-center">
              {this.timeGenarator(this.props.message.Time)}
            </div>
            <div className="col-2 h-100 d-flex align-items-center justify-content-center text-center">
              {this.props.message.status === "read" ? (
                <>
                  <i
                    title="Read"
                    className="bi bi-check-all text-success fs-4 me-2"
                  ></i>
                </>
              ) : (
                <>
                  <i
                    title="sent successfully"
                    className="bi bi-check text-success fs-4 me-2"
                  ></i>
                </>
              )}
              <i
                onClick={() => {
                  this.deleteMSG(this.props.message.SM_ID);
                }}
                className="bi bi-trash btn-scale c-pointer"
              ></i>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.show}
          onHide={() => {
            this.handleClose();
          }}
          // backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.message.subject}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className="fs-4 text-muted">
              Receiver: {this.props.message.receiver}
            </span>{" "}
            <br />
            <br />
            <p id="msg" className="new-line p-3">
              {this.props.message.message}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default SentItem;
