import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class Lcard extends Component {
  state = { show: false };
  walletGenerator = (w) => {
    let newWallet = w.split("");

    let length = newWallet.length;

    let temp =
      newWallet[0] +
      newWallet[1] +
      newWallet[2] +
      newWallet[3] +
      newWallet[4] +
      "..." +
      newWallet[length - 4] +
      newWallet[length - 3] +
      newWallet[length - 2] +
      newWallet[length - 1];

    return temp;
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  handleOpen = () => {
    this.setState({ show: true });
  };
  render() {
    return (
      <>
        {" "}
        <div
          onClick={() => {
            this.handleOpen();
          }}
          id={this.props.target ? "target" : "item"}
          className="col-6 col-sm-4 col-md-3  col-lg-2 text-center c-pointer"
        >
          <div className="L-card">
            <div className="card bg-dark max-w-200 w-100">
              <h5 className="card-title fs-6 fw-bolder m-0 mb-1 mt-1 text-white">
                {this.walletGenerator(this.props.item.wallet_address)}
              </h5>
              <img
                src={`./NFTImages/${this.props.item.profilepic_id}.jpg`}
                className="card-img-top"
                alt="..."
              />
              {this.props.target ? (
                <>
                  <span className="badge text-bg-success mt-2">
                    Rank #{this.props.itemRank}
                  </span>
                </>
              ) : (
                <>
                  <span className="badge text-bg-danger mt-2">
                    Rank #{this.props.itemRank}
                  </span>
                </>
              )}

              <span className="badge mt-1">
                <a
                  rel="noopener noreferrer"
                  className="not-underline text-white"
                  href={`https://twitter.com/${this.props.item.Twitter_Id}`}
                  target="_blank"
                >
                  <i className="bi bi-twitter fs-6 text-info"></i>{" "}
                  {this.props.item.Twitter_Id}
                </a>
              </span>
              <span className="badge mt-1">SCORE {this.props.item.score}</span>
              <div className="card-body fs-6 p-0">
                <p className="card-text m-0 p-0">
                  <span className="badge text-bg-dark me-1">
                    <img
                      alt="AKU XP"
                      className=""
                      width="20"
                      height="20"
                      src="./asset/Menu/xp.png"
                    />
                    <span className="fs-7 ms-1 fw-bold">
                      {this.props.item.xp}
                    </span>
                  </span>

                  <span className="badge text-bg-dark me-1">
                    <img
                      alt="AKU "
                      className=""
                      width="20"
                      height="20"
                      src="./asset/Menu/aku.png"
                    />
                    <span className="fs-7 ms-1 fw-bold">
                      {this.props.aku ? `${this.props.aku}` : "0"}
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={() => {
            this.handleClose();
          }}
          // backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                {this.walletGenerator(this.props.item.wallet_address)}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-self-center g-1 w-100 g-2">
              <div className="col-12 col-md-6  h-100 p-2">
                <div className="d-flex bg-black flex-column border border-1 p-2 BR-5">
                  <span className="badge bg-transparent fw-bold fs-5 mb-2">
                    {this.props.item.username}
                  </span>
                  <span className="text-center">
                    <img
                      src={`./NFTImages/${this.props.item.profilepic_id}.jpg`}
                      className="user-image border-0"
                      alt="..."
                    />
                  </span>
                  <span className="badge text-bg-danger mt-2">
                    Rank #{this.props.itemRank}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6  h-100 p-2">
                <div className="d-flex flex-column border border-1 p-2 BR-5">
                  <span className="badge text-bg-dark me-1 text-start">
                    <img
                      alt="AKU"
                      className=""
                      width="20"
                      height="20"
                      src="./asset/Menu/aku.png"
                    />
                    <span className="fs-7 ms-1 fw-bold">
                      {this.props.aku ? `${this.props.aku}` : "0"}
                    </span>
                  </span>
                  <span className="badge text-bg-dark me-1 text-start">
                    <img
                      alt="AKU XP"
                      className=""
                      width="20"
                      height="20"
                      src="./asset/Menu/xp.png"
                    />
                    <span className="fs-7 ms-1 fw-bold">
                      {this.props.item.xp}
                    </span>
                  </span>
                  <span className="badge text-bg-dark me-1 text-start">
                    SCORE: {this.props.item.score}
                  </span>
                  <span className="badge text-bg-dark me-1 text-start">
                    <a
                      rel="noopener noreferrer"
                      className="not-underline text-white"
                      href={`https://twitter.com/${this.props.item.Twitter_Id}`}
                      target="_blank"
                    >
                      <i className="bi bi-twitter fs-6 text-info"></i>{" "}
                      {this.props.item.Twitter_Id}
                    </a>
                  </span>
                  {this.props.item.Telegram_Id !== null ? (
                    <>
                      <span className="badge text-bg-dark me-1 text-start">
                        <a
                          rel="noopener noreferrer"
                          className="not-underline text-white"
                          href={`https://t.me/${this.props.item.Telegram_Id.substring(
                            1
                          )}`}
                          target="_blank"
                        >
                          <i className="bi bi-telegram fs-6 text-info"></i>{" "}
                          {this.props.item.Telegram_Id}
                        </a>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Lcard;
