import React, { Component } from "react";
import OnceQuestItem from "./OnceQuestItem";
class OnceQuests extends Component {
  state = {
    temp: this.props.SQpass,
  };
  render() {
    return (
      <>
        {this.props.specialQuestsList.map((item) => {
          var status;
          var lock = false;
          var lockReason;

          if (this.props.SQpass[item.SQ_ID].status === "undone") {
            status = "undone";
          }
          if (this.props.SQpass[item.SQ_ID].status === "passed") {
            status = "passed";
          }
          if (this.props.SQpass[item.SQ_ID].status === "pending") {
            status = "pending";
          }
          if (this.props.SQpass[item.SQ_ID].status === "fail") {
            status = "fail";
          }
          if (item.pre_score > this.props.user.score) {
            lock = true;
            lockReason = `You need ${
              item.pre_score - this.props.user.score
            } more point to unlock quest`;
          }
          if (item.pre_aku > this.props.akuCount) {
            lock = true;
            lockReason = `You need ${
              item.pre_aku - this.props.akuCount
            } more AkuAku to unlock quest`;
          }
          if (item.pre_uka > this.props.ukaCount) {
            lock = true;
            lockReason = `You need ${
              item.pre_uka - this.props.ukaCount
            } more UkaUka to unlock quest`;
          }
          if (item.pre_nft > this.props.nftCount) {
            lock = true;
            lockReason = `You need ${
              item.pre_nft - this.props.nftCount
            } more AkuAku NFT to unlock quest`;
          }
          if (item.disabled === 0) {
            if (
              this.props.SQpass[item.SQ_ID].status === "passed" &&
              item.show_after_pass === 0
            ) {
              return null;
            } else {
              return (
                <OnceQuestItem
                  key={item.SQ_ID}
                  user={this.props.user}
                  answer={this.props.SQpass[item.SQ_ID]}
                  lockReason={lockReason}
                  lock={lock}
                  status={status}
                  item={item}
                  wallet={this.props.wallet}
                  refreshQuests={this.props.refreshQuests}
                  updateuser={this.props.updateUser}
                />
              );
            }
          } else {
            return null;
          }
        })}
      </>
    );
  }
}

export default OnceQuests;
