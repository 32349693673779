import React, { Component } from "react";

class Home extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="min-vh-100 work">
          <div className="d-flex justify-content-center align-self-center min-h-work">
            <video
              id="1"
              className="op-8 d-flex justify-content-center align-self-center max-h-work mw-100"
              muted
              loop
              autoPlay
              playsInline
            >
              <source src="./asset/Home/home.webm" type="video/webm" />
              <source src="./asset/Home/home.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
