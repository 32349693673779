import React, { Component } from "react";
class Mint extends Component {
  
  state = {};
  render() {
    return (
      <>
        <div className="min-vh-100 work">
          <div className="d-flex justify-content-center align-self-center min-h-work">
            Mint
          </div>
        </div>
      </>
    );
  }
}

export default Mint;
