import React, { Component } from "react";
class SignUpForm extends Component {
  state = {
    userTemp: {
      username: "",
      twitter_id: "",
      telegram_id: "",
    },
  };
  checkId = (i) => {
    var temp = i.split("");
    if (temp[0] === "@") {
      return i;
    } else {
      return `@${i}`;
    }
  };
  usedInviteCode = async (w, s, c) => {
    const get = await fetch("https://api.the369.xyz/updateInviteCode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        status: s,
        inviteCode: c,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  checkUsername = (u) => {
    const l = u.length;
    if (l > 15) {
      return "Username max characters is 15";
    } else {
      if (l < 2) {
        return "Username must be at least 2 characters long ";
      } else {
        if (u.replaceAll(" ", "") === "369") {
          return "Username can't be: 369 ";
        } else {
          return "ok";
        }
      }
    }
  };
  waitingBTN = (id) => {
    const bt = document.getElementById(id);
    bt.classList.add("disable-link");
    bt.innerHTML = "wait...";
  };
  unWaitingBTN = (id, o) => {
    const bt = document.getElementById(id);
    bt.classList.remove("disable-link");
    bt.innerHTML = o;
  };
  error = (id, m) => {
    const e = document.getElementById(id);
    e.innerHTML = m;
  };
  suc = (id, m) => {
    const s = document.getElementById(id);
    s.innerHTML = m;
  };
  refreshForm = () => {
    const username = document.getElementById("username");
    const Telegram_Id = document.getElementById("telegram_id");
    const twitter_id = document.getElementById("twitter_id");
    username.value = "";
    Telegram_Id.value = "";
    twitter_id.value = "";
  };
  handleSignUp = async () => {
    var tempXp;
    var tempScore;
    this.error("err", "");
    this.suc("suc", "");
    this.waitingBTN("signup-btn");
    if (this.checkUsername(this.state.userTemp.username) !== "ok") {
      this.unWaitingBTN("signup-btn", "Sign Up");
      this.error("err", this.checkUsername(this.state.userTemp.username));
    } else {
      if (this.state.userTemp.twitter_id !== "") {
        if (this.props.code !== null) {
          tempXp = this.props.code.signUpScore * 3;
          tempScore = this.props.code.signUpScore;
        } else {
          tempXp = 0;
          tempScore = 0;
        }

        const res = await this.signUpUser(
          this.props.wallet,
          this.state.userTemp.username,
          this.checkId(this.state.userTemp.twitter_id),
          this.checkId(this.state.userTemp.telegram_id),
          tempScore,
          tempXp,
          this.props.NFTcount,
          0
        );
        if (res) {
          if (this.props.code !== null) {
            this.usedInviteCode(
              this.props.wallet,
              "used",
              this.props.code.code
            );
          }

          this.suc("suc", "Congratulations! You Are Successfully Signed Up!");
          this.refreshForm();
          this.unWaitingBTN("signup-btn", "Sign Up");
          setTimeout(() => {
            document.getElementById("update-btn").click();
          }, 1000);
        } else {
        }
      } else {
        this.unWaitingBTN("signup-btn", "Sign Up");
        this.error("err", "Twitter Id can not be empty");
      }
    }
  };
  setInput = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      userTemp: {
        ...prevState.userTemp,
        [name]: value,
      },
    }));
  };

  signUpUser = async (w, u, tw, t, s, x, c, p) => {
    const get = await fetch("https://api.the369.xyz/signUpUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        username: u,
        twitter_id: tw,
        telegram_id: t,
        score: s,
        NFTcount: c,
        xp: x,
        profilepic_id: p,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };

  render() {
    return (
      <>
        <button
          id="update-btn"
          className="d-none"
          onClick={this.props.updateUser}
        ></button>

        <div className="row justify-content-evenly align-self-center max-w-500">
          {/* <div className="col-12 text-center fs-2 fw-bold">Sign Up</div> */}

          <div className="col-12 text-start mt-2 BR-5 d-flex flex-column p-2 h-300">
            <span className="fw-bold fs-5">Create your account</span>
            <span className="mt-4">
              <input
                onChange={this.setInput}
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white"
                type="text"
                placeholder="Username"
                name="username"
                id="username"
              />
            </span>
            <span>
              <input
                onChange={this.setInput}
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white"
                type="text"
                placeholder="Twitter ID"
                name="twitter_id"
                id="twitter_id"
              />
            </span>
            <span>
              <input
                onChange={this.setInput}
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white"
                type="text"
                placeholder="Telegram ID"
                name="telegram_id"
                id="telegram_id"
              />
            </span>
            <span className="fs-6 text-success" id="suc"></span>
            <span className="fs-6 text-danger" id="err"></span>
            <button
              id="signup-btn"
              onClick={this.handleSignUp}
              className="btn-s bg-green align-self-start ms-3 mt-3"
            >
              Sign Up
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default SignUpForm;
