import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
class Get6Keys extends Component {
  state = { show: false };
  handleClose = () => {
    this.setState({ show: false });
  };
  handleOpen = () => {
    this.setState({ show: true });
  };
  removeXp = async (w, x) => {
    const get = await fetch("https://api.the369.xyz/removeXp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        xp: x,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  increaseBuyKey = async (id, BK) => {
    const get = await fetch("https://api.the369.xyz/increaseBuyKey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        user_id: id,
        BK: BK,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("update-btn").click();
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  insertNotif = async (s, m, xp, score, user) => {
    const get = await fetch("https://api.the369.xyz/insertNotif", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        subject: s,
        message: m,
        xp: xp,
        score: score,
        user: user,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  get6key = async () => {
    const res = await this.removeXp(this.props.user.wallet_address, 3);
    if (res) {
      this.increaseBuyKey(this.props.user.user_id, 6);
      this.insertNotif(
        "Receive 6 Souls",
        "You receive 6 Souls for 3 xp",
        0,
        0,
        this.props.user.wallet_address
      );
      this.handleClose();
    } else {
      alert("Something wrong");
    }
  };
  handleget6key = () => {
    this.handleOpen();
  };
  render() {
    return (
      <>
        <button
          id="update-btn"
          className="d-none"
          onClick={this.props.updateUser}
        ></button>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshQuests}
        ></button>
        <div className="card bg-dark max-w-200  d-flex ms-auto me-auto mt-5 text-center p-3 BR-5 soul ">
          {/* <img
            alt="AKU KEYS"
            className="d-flex ms-auto me-auto border border-1 border-light BR-5"
            width="100"
            height="100"
            src="./asset/logo/keys.png"
          /> */}
          {/* <span className="bg-green  BR-5 h-100px">
            <span className="key-6 text-center border border-3 border-light  d-flex ms-auto me-auto  ">
              <span className="c-reg fs-4 text-white">6</span>
              <i className="bi bi-key kk fs-4 text-white"></i>
            </span>
          </span> */}
          <img
            alt="AKU XP"
            className="ms-auto me-auto p-1  "
            width="50"
            src="./asset/logo/soulbadge.png"
          />

          <div className="card-body fs-6 p-0">
            <span
              onClick={this.handleget6key}
              className="c-pointer badge bg-success mt-1 fs-6 "
            >
              <span className="fs-7 me-1 pt-3 fw-bold">3</span>
              <img
                alt="AKU XP"
                className=""
                width="20"
                height="20"
                src="./asset/Menu/xp.png"
              />
            </span>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={() => {
            this.handleClose();
          }}
          // backdrop="static"
          keyboard={false}
          centered
          size="sm"
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.user.xp > 2 ? (
              <>
                <div className="d-flex flex-column">
                  <span>Get 6 Souls for 3 XP ?</span>
                  <span
                    onClick={this.get6key}
                    className="c-pointer badge bg-success mt-1 fs-6 w-25"
                  >
                    <span className="fs-7 me-1 pt-3 fw-bold">3</span>
                    <img
                      alt="AKU XP"
                      className=""
                      width="20"
                      height="20"
                      src="./asset/Menu/xp.png"
                    />
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex flex-column">
                  You don't have enough XP
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Get6Keys;
