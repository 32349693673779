import React, { Component } from "react";
import "../../STISTYLE.css";

class SpecialOneItem extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="w-70-vh text-center  ms-auto me-auto ">
          {" "}
          <ol reversed class="paces  " lang="en">
            <li class="pace js">
              19
              <blockquote class="quote js"></blockquote>
            </li>
            <li class="pace css">
              5<blockquote class="quote css"></blockquote>
            </li>
            <li class="pace html">
              12
              <blockquote class="quote html"></blockquote>
            </li>
            <li class="pace url">
              3<blockquote class="quote url"></blockquote>
            </li>
            <li class="pace http">
              25
              <blockquote class="quote http"></blockquote>
            </li>
            <li class="pace tcpip">
              3<blockquote class="quote tcpip"></blockquote>
            </li>
            <li class="pace tcpip2">
              <blockquote class="quote tcpip"></blockquote>
            </li>
          </ol>
        </div>
      </>
    );
  }
}

export default SpecialOneItem;
