import React, { Component } from "react";
import Compose from "./Compose";
import Inbox from "./Inbox";
import MessageMenu from "./MessageMenu";
import Sent from "./Sent";
class Messages extends Component {
  
  state = { inboxPage: true, sentPage: false, composePage: false };
  refreshState = () => {

    this.setState({
      inboxPage: false,
      sentPage: false,
      composePage: false,
    });
  };
  handleInbox = () => {

    this.refreshState();
    this.setState({ inboxPage: true });
  };
  handleSent = () => {

    this.refreshState();
    this.setState({ sentPage: true });
  };
  handleCompose = () => {

    this.refreshState();
    this.setState({ composePage: true });
  };
  render() {
    return (
      <>
        <div className="min-vh-100 work d-flex justify-content-center align-self-center">
          <div className="min-h-work d-flex justify-content-center align-self-center w-100">
            <div className="row m-3 justify-content-evenly align-self-center w-100">
              <div className="col-12 mt-3 h-400 div-work d-flex justify-content-center max-w-992">
                <div className="row align-self-center g-1 w-100 h-100">
                  <MessageMenu
                    inbox={this.state.inboxPage}
                    sent={this.state.sentPage}
                    compose={this.state.composePage}
                    onInbox={() => {
                      this.handleInbox();
                    }}
                    onSent={() => {
                      this.handleSent();
                    }}
                    onCompose={() => {
                      this.handleCompose();
                    }}
                  />

                  {this.state.inboxPage ? (
                    <>
                      <Inbox
                        userMessage={this.props.userMessage}
                        broadcast={this.props.broadcast}
                        broadcastStatus={this.props.broadcastStatus}
                        refreshMessages={this.props.refreshMessages}
                        wallet={this.props.wallet}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.sentPage ? (
                    <>
                      <Sent
                        wallet={this.props.wallet}
                        userSentMessage={this.props.userSentMessage}
                        refreshMessages={this.props.refreshMessages}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.composePage ? (
                    <>
                      <Compose
                        user={this.props.user}
                        refreshMessages={this.props.refreshMessages}
                        wallet={this.props.wallet}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Messages;
