import React, { Component } from "react";
class ChatGPT extends Component {
  handleEnter = (event) => {
    console.log("key");
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.handleSend();
    }
  };
  disFunc = () => {
    const func = document.getElementById("func");
    func.classList.add("disable-link");
  };
  enFunc = () => {
    const func = document.getElementById("func");
    func.classList.remove("disable-link");
  };
  clearScreen = () => {
    const res = document.getElementById("res");
    var temp = `<span>${"How can i help you ?"}</span>`;
    res.innerHTML = temp;
  };

  think = () => {
    this.unfocusForm();
    this.disFunc();
    document.getElementById("think").classList.remove("d-none");
  };
  focusForm = () => {
    document.getElementById("form").focus();
  };
  unfocusForm = () => {
    document.getElementById("form").disabled = true;
    document.getElementById("form").disabled = false;
  };
  unthink = () => {
    this.setState({ text: null });
    this.enFunc();
    document.getElementById("think").classList.add("d-none");
    this.focusForm();
  };
  clearForm = () => {
    document.getElementById("form").value = "";
  };
  getchatgpt = async (p) => {
    const get = await fetch("http://localhost:5002/chat", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        p: p,
      }),
    }).then((res) => res.json());
    return get;
  };
  putAnswer = (r) => {
    console.log("a");
    this.unthink();
    const res = document.getElementById("res");
    var temp = `<span>${r}</span>`;
    res.innerHTML += temp;
  };
  putQuestion = (r) => {
    console.log("q");
    const res = document.getElementById("res");
    var temp = `<span>>: ${r}</span>`;
    res.innerHTML += temp;
    this.think();
  };
  state = { text: null };
  handleSend = async () => {
    if (
      this.state.text.replaceAll(" ", "") === "" ||
      this.state.text === null
    ) {
      this.clearForm();
      return null;
    } else {
      this.putQuestion(this.state.text);
      this.clearForm();
      // console.log(this.state.text);
      // this.putAnswer(this.state.text);
      const t = await this.getchatgpt(this.state.text);
      console.log(t.response.replaceAll("\n", ""));
      this.putAnswer(t.response.replaceAll("\n", ""));
    }
  };
  setInput = (e) => {
    // eslint-disable-next-line
    const { name, value } = e.target;

    this.setState({ text: value });
  };
  //dsddfssd
  render() {
    return (
      <>
        {/* ChatGPT */}
        <div className="min-vh-100 work d-flex flex-column chat-gpt px-0 ">
          <div onClick={this.focusForm} className="chat-area p-0">
            <div id="res" className="d-flex flex-column px-3 pt-3 pb-0">
              <span>{"How can i help you ?"} </span>{" "}
            </div>
            <div id="think" className="d-none px-3">
              {"I'm thinking..."}
            </div>
          </div>

          <div id="func" className="mt-auto  d-flex ">
            <div class="input-group mb-3">
              <input
                onChange={this.setInput}
                onKeyDown={this.handleEnter}
                type="text"
                id="form"
                class="form-control w-300 form-control-lg m-2  chat-input"
                // className="chat-input"
              />
              <button
                onClick={this.clearScreen}
                class="btn  border-success me-2 text-white chat-btn mt-2"
                type="button"
                id="button-addon2"
                title="Clear Screen"
              >
                <i class="bi bi-trash"></i>
              </button>
              <button
                onClick={this.handleSend}
                class="btn  border-success me-2 text-white chat-btn mt-2"
                type="button"
                id="button-addon2"
              >
                <i class="bi bi-send"></i>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ChatGPT;
