import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class OnceQuestItem extends Component {
  state = {
    show: false,
    answer: null,
    image: null,
    fileName: null,
    lockItem: {
      subject: "This is 369 subject",
      message: "This is 369 Message",
      xp: 9,
      score: 6,
    },
  };
  path = "https://api.the369.xyz/images/";
  timeGenarator = (t) => {
    // eslint-disable-next-line
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    // const [H, M, S] = time.split(":");

    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  addScoreXp = async (x, s) => {
    const get = await fetch("https://api.the369.xyz/addScoreXp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: this.props.wallet,
        xp: x,
        score: s,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
    }
    if (get.status === "failed") {
    }
  };
  waitingBTN = (id) => {
    const bt = document.getElementById(id);
    bt.classList.add("disable-link");
    bt.innerHTML = "wait...";
  };
  unWaitingBTN = (id, o) => {
    const bt = document.getElementById(id);
    bt.classList.remove("disable-link");
    bt.innerHTML = o;
  };
  error = (m) => {
    const e = document.getElementById("err");
    e.innerHTML = m;
  };
  suc = (m) => {
    const s = document.getElementById("suc");
    s.innerHTML = m;
  };
  handleEditUpload = async () => {
    this.waitingBTN("img-btn");
    this.error("");
    this.suc("");
    if (this.state.image === null) {
      this.error("Select a file !");
      this.unWaitingBTN("img-btn", "Upload");
    } else {
      var ext = this.state.image.name.substr(
        this.state.image.name.lastIndexOf(".") + 1
      );
      if (ext === "png" || ext === "PNG" || ext === "jpg" || ext === "JPG") {
        const res = await this.uploadImage(this.state.image);
        if (res) {
          await this.editSpecialPass(
            this.state.fileName,
            this.props.answer.SQP_ID
          );
          this.suc("Your file added successfuly, Wait for review.");
          this.unWaitingBTN("img-btn", "Upload");
          document.getElementById("img-btn").classList.add("disable-link");
          // setTimeout(() => {
          //   this.handleClose();
          // }, 1000);
          document.getElementById("ref-quest").click();
          setTimeout(() => {
            document.getElementById("ref-quest").click();
          }, 500);
        }
      } else {
        this.error("Your file must be JPG or PNG");
        this.unWaitingBTN("img-btn", "Upload");
      }
    }
  };
  handleUpload = async () => {
    this.waitingBTN("img-btn");
    this.error("");
    this.suc("");
    if (this.state.image === null) {
      this.error("Select a file !");
      this.unWaitingBTN("img-btn", "Upload");
    } else {
      var ext = this.state.image.name.substr(
        this.state.image.name.lastIndexOf(".") + 1
      );
      if (ext === "png" || ext === "PNG" || ext === "jpg" || ext === "JPG") {
        const res = await this.uploadImage(this.state.image);
        if (res) {
          await this.subSpecialPass(
            this.props.wallet,
            "pending",
            this.state.fileName,
            this.props.item.SQ_ID,
            "",
            null
          );
          this.suc("Your file added successfuly, Wait for review.");
          this.unWaitingBTN("img-btn", "Upload");
          document.getElementById("img-btn").classList.add("disable-link");
          // setTimeout(() => {
          //   this.handleClose();
          // }, 1000);
          document.getElementById("ref-quest").click();
          setTimeout(() => {
            document.getElementById("ref-quest").click();
          }, 500);
        }
      } else {
        this.error("Your file must be JPG or PNG");
        this.unWaitingBTN("img-btn", "Upload");
      }
    }
  };
  uploadImage = async (t) => {
    let formData = new FormData();
    formData.append("file", this.state.image);
    await fetch("https://api.the369.xyz/upload", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());
    return true;
  };
  editSpecialPass = async (answer, id) => {
    const get = await fetch("https://api.the369.xyz/editSpecialPass", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        answer: answer,
        SQP_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  subSpecialPass = async (w, s, a, q, msg, vt) => {
    const get = await fetch("https://api.the369.xyz/insertSpecialPass", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        status: s,
        answer: a,
        quest: q,
        msg: msg,
        verify_Time: vt,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  setInput = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      answer: {
        ...prevState.answer,
        [name]: value,
      },
    }));
  };
  renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  };
  generateRandom = () => {
    let x = Math.floor(Math.random() * 1000000 + 369);
    return x;
  };
  genarateNameOfFile = (f) => {
    var temp;
    temp = `${this.props.user.Twitter_Id.substring(1)}_SQ${
      this.props.item.SQ_ID
    }_${this.generateRandom()}.${f}`;
    return temp;
  };
  onFileChange = (e) => {
    this.error("");
    this.suc("");
    var tempFile = e.target.files[0];
    document.getElementById("file-suc").innerHTML = tempFile.name;
    var ext = tempFile.name.substr(tempFile.name.lastIndexOf(".") + 1);

    const name = this.genarateNameOfFile(ext);

    const r = this.renameFile(tempFile, name);

    this.setState({ image: r });
    this.setState({ fileName: name });
  };
  handleEditText = async () => {
    this.waitingBTN("text-btn");
    this.error("");
    this.suc("");
    const res = await this.editSpecialPass(
      this.state.answer.text,
      this.props.answer.SQP_ID
    );
    if (res) {
      this.suc("Your answer added successfuly, Wait for review.");
      this.unWaitingBTN("text-btn", "Submit");
      document.getElementById("text-btn").classList.add("disable-link");
      // setTimeout(() => {
      //   this.handleClose();
      // }, 1000);
      document.getElementById("ref-quest").click();
      setTimeout(() => {
        document.getElementById("ref-quest").click();
      }, 500);
    } else {
    }
  };
  handleEditLink = async () => {
    this.waitingBTN("link-btn");
    this.error("");
    this.suc("");
    const res = await this.editSpecialPass(
      this.state.answer.link,
      this.props.answer.SQP_ID
    );
    if (res) {
      this.suc("Your link added successfuly, Wait for review.");
      this.unWaitingBTN("link-btn", "Submit");
      document.getElementById("link-btn").classList.add("disable-link");
      // setTimeout(() => {
      //   this.handleClose();
      // }, 1000);
      document.getElementById("ref-quest").click();
      setTimeout(() => {
        document.getElementById("ref-quest").click();
      }, 500);
    } else {
    }
  };
  handleSubText = async () => {
    this.waitingBTN("text-btn");
    this.error("");
    this.suc("");
    const res = await this.subSpecialPass(
      this.props.wallet,
      "pending",
      this.state.answer.text,
      this.props.item.SQ_ID,
      "",
      null
    );
    if (res) {
      this.suc("Your answer added successfuly, Wait for review.");
      this.unWaitingBTN("text-btn", "Submit");
      document.getElementById("text-btn").classList.add("disable-link");
      // setTimeout(() => {
      //   this.handleClose();
      // }, 1000);
      document.getElementById("ref-quest").click();
      setTimeout(() => {
        document.getElementById("ref-quest").click();
      }, 500);
    } else {
    }
  };
  handleSubLink = async () => {
    this.waitingBTN("link-btn");
    this.error("");
    this.suc("");
    const res = await this.subSpecialPass(
      this.props.wallet,
      "pending",
      this.state.answer.link,
      this.props.item.SQ_ID,
      "",
      null
    );
    if (res) {
      this.suc("Your link added successfuly, Wait for review.");
      this.unWaitingBTN("link-btn", "Submit");
      document.getElementById("link-btn").classList.add("disable-link");
      // setTimeout(() => {
      //   this.handleClose();
      // }, 1000);
      document.getElementById("ref-quest").click();
      setTimeout(() => {
        document.getElementById("ref-quest").click();
      }, 500);
    } else {
    }
  };
  handleSubNull = async () => {
    this.waitingBTN("null-btn");
    this.error("");
    this.suc("");
    const res = await this.subSpecialPass(
      this.props.wallet,
      "passed",
      "",
      this.props.item.SQ_ID,
      "",
      null
    );
    if (res) {
      this.addScoreXp(this.props.item.xp, this.props.item.score);
      this.suc("Congrats, You Claim It");
      this.unWaitingBTN("null-btn", "Claim Reward");
      document.getElementById("null-btn").classList.add("disable-link");
      // setTimeout(() => {
      //   this.handleClose();
      // }, 1000);
      document.getElementById("ref-quest").click();
      setTimeout(() => {
        document.getElementById("ref-quest").click();
      }, 500);
      document.getElementById("update-btn").click();
    } else {
    }
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  handleOpen = () => {
    this.setState({ show: true });
  };
  render() {
    return (
      <>
        <button
          id="update-btn"
          className="d-none"
          onClick={this.props.updateUser}
        ></button>
        <button
          id="ref-quest"
          className="d-none"
          onClick={this.props.refreshQuests}
        ></button>
        {this.props.lock ? (
          <>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center align-self-center position-relative">
              <div className="quest-item-special quest-item-special-hover d-flex flex-column lock-quest">
                <span className="fw-bolder fs-3">
                  {this.state.lockItem.subject}
                </span>
                <span className="">{this.state.lockItem.message}</span>
                <span className="mt-auto bg-dark fw-bold w-fit p-1 BR-5 fs-6 quest-special-xp">
                  {this.state.lockItem.xp}xp
                </span>

                <span className="mt-auto bg-dark fw-bold w-fit p-1 BR-5 fs-6 quest-special-xp position-absolute bottom-0 end-0 me-2 mb-2">
                  {this.state.lockItem.score}Point
                </span>
              </div>
              <div className="position-absolute top-50 start-50 translate-middle none-blur text-center">
                <i className="bi bi-lock fs-1 text-white rounded-circle border border-1 p-2 mb-2"></i>
                <br />
                <br />
                <span className="mt-2">{this.props.lockReason}</span>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {!this.props.lock && this.props.status === "undone" ? (
          <>
            <div
              onClick={() => {
                this.handleOpen();
              }}
              className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center align-self-center"
            >
              <div className="quest-item-special quest-item-special-hover d-flex flex-column">
                <span className="fw-bolder fs-4">
                  {this.props.item.subject}
                </span>
                <span className="mt-2">{`${this.props.item.message.substring(
                  0,
                  this.props.item.message.length / 2
                )}...`}</span>
                {this.props.item.xp !== 0 ? (
                  <>
                    <span className="mt-auto bg-dark fw-bold w-fit p-1 BR-5 fs-6 quest-special-xp">
                      {this.props.item.xp}xp
                    </span>
                  </>
                ) : (
                  <></>
                )}

                {this.props.item.score !== 0 ? (
                  <>
                    <span className="mt-auto bg-dark fw-bold w-fit p-1 BR-5 fs-6 quest-special-xp position-absolute bottom-0 end-0 me-2 mb-2">
                      {this.props.item.score}Point
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {!this.props.lock && this.props.status === "passed" ? (
          <>
            <div
              onClick={() => {
                this.handleOpen();
              }}
              className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center align-self-center "
            >
              <div className="quest-item-special quest-item-special-hover d-flex flex-column">
                <span className="fs-7 fw-bold text-success position-absolute top-0 end-0 me-2 ">
                  Completed
                </span>
                <span className="fw-bolder fs-4">
                  {this.props.item.subject}
                </span>
                <span className="mt-2">{`${this.props.item.message.substring(
                  0,
                  this.props.item.message.length / 2
                )}...`}</span>
                {this.props.item.xp !== 0 ? (
                  <>
                    <span className="mt-auto bg-dark fw-bold w-fit p-1 BR-5 fs-6 quest-special-xp">
                      {this.props.item.xp}xp
                    </span>
                  </>
                ) : (
                  <></>
                )}

                {this.props.item.score !== 0 ? (
                  <>
                    <span className="mt-auto bg-dark fw-bold w-fit p-1 BR-5 fs-6 quest-special-xp position-absolute bottom-0 end-0 me-2 mb-2">
                      {this.props.item.score}Point
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {!this.props.lock && this.props.status === "pending" ? (
          <>
            <div
              onClick={() => {
                this.handleOpen();
              }}
              className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center align-self-center"
            >
              <div className="quest-item-special quest-item-special-hover d-flex flex-column">
                <span className="fs-7 fw-bold text-warning position-absolute top-0 end-0 me-2 ">
                  In Review
                </span>
                <span className="fw-bolder fs-4">
                  {this.props.item.subject}
                </span>
                <span className="mt-2">{`${this.props.item.message.substring(
                  0,
                  this.props.item.message.length / 2
                )}...`}</span>
                {this.props.item.xp !== 0 ? (
                  <>
                    <span className="mt-auto bg-dark fw-bold w-fit p-1 BR-5 fs-6 quest-special-xp">
                      {this.props.item.xp}xp
                    </span>
                  </>
                ) : (
                  <></>
                )}

                {this.props.item.score !== 0 ? (
                  <>
                    <span className="mt-auto bg-dark fw-bold w-fit p-1 BR-5 fs-6 quest-special-xp position-absolute bottom-0 end-0 me-2 mb-2">
                      {this.props.item.score}Point
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {!this.props.lock && this.props.status === "fail" ? (
          <>
            <div
              onClick={() => {
                this.handleOpen();
              }}
              className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center align-self-center"
            >
              <div className="quest-item-special quest-item-special-hover d-flex flex-column">
                <span className="fs-7 fw-bold text-danger position-absolute top-0 end-0 me-2 ">
                  Rejected
                </span>
                <span className="fw-bolder fs-4">
                  {this.props.item.subject}
                </span>
                <span className="mt-2">{`${this.props.item.message.substring(
                  0,
                  this.props.item.message.length / 2
                )}...`}</span>
                {this.props.item.xp !== 0 ? (
                  <>
                    <span className="mt-auto bg-dark fw-bold w-fit p-1 BR-5 fs-6 quest-special-xp">
                      {this.props.item.xp}xp
                    </span>
                  </>
                ) : (
                  <></>
                )}

                {this.props.item.score !== 0 ? (
                  <>
                    <span className="mt-auto bg-dark fw-bold w-fit p-1 BR-5 fs-6 quest-special-xp position-absolute bottom-0 end-0 me-2 mb-2">
                      {this.props.item.score}Point
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <Modal
          show={this.state.show}
          onHide={() => {
            this.handleClose();
          }}
          // backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative">
                {" "}
                {this.props.item.subject}{" "}
                <span className="position-absolute top-0 start-120 translate-middle  bg-light text-muted px-1 rounded-pill fs-8">
                  special<span className="visually-hidden">special</span>
                </span>
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-self-center g-1 w-100 g-2">
              <div className="col-12 col-md-6  h-100 p-2">
                <div className="d-flex flex-column border border-1 p-2 BR-5">
                  <span className="fw-bold fs-5">
                    <img
                      alt="AKU MISSION"
                      className="me-2"
                      width="30"
                      height="30"
                      src="./asset/logo/mission.png"
                    />
                    MISSION
                  </span>
                  <span className=" fs-6">{this.props.item.message}</span>
                  <hr className="divider"></hr>
                  {this.props.item.guide !== null &&
                  this.props.item.guide !== "" ? (
                    <>
                      <span className="fw-bold fs-5">
                        <img
                          alt="AKU GUIDE"
                          className="me-2"
                          width="30"
                          height="30"
                          src="./asset/logo/guide.png"
                        />
                        GUIDE
                      </span>
                      <span className=" fs-6">{this.props.item.guide}</span>
                      <hr className="divider"></hr>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.props.item.submission !== null &&
                  this.props.item.submission !== "" ? (
                    <>
                      <span className="fw-bold fs-5">
                        <img
                          alt="AKU SUBMISSION"
                          className="me-2"
                          width="30"
                          height="30"
                          src="./asset/logo/sub.png"
                        />
                        SUBMISSION
                      </span>
                      <span className=" fs-6">
                        {this.props.item.submission}
                      </span>
                      <hr className="divider"></hr>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.props.item.answer_type === "link" &&
                  this.props.status === "undone" ? (
                    <>
                      <span className="w-100 text-center mb-2">
                        <input
                          onChange={this.setInput}
                          id="link"
                          name="link"
                          className="form-control form-control-sm border-success bg-dark text-white"
                          type="text"
                          placeholder="https://twitter.com/..."
                        />
                        <span className="fs-6 text-success" id="suc"></span>
                        <span className="fs-6 text-danger" id="err"></span>
                        <button
                          onClick={() => {
                            this.handleSubLink();
                          }}
                          id="link-btn"
                          className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 w-100"
                        >
                          Submit
                        </button>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.props.item.answer_type === null &&
                  this.props.status === "undone" ? (
                    <>
                      <span className="w-100 text-center mb-2">
                        <button
                          onClick={() => {
                            this.handleSubNull();
                          }}
                          className="btn-s bg-green align-self-start ms-3 mt-3"
                          id="null-btn"
                        >
                          Claim Reward
                        </button>
                      </span>
                      <span
                        className="fs-6 text-success text-center"
                        id="suc"
                      ></span>
                      <span
                        className="fs-6 text-danger text-center"
                        id="err"
                      ></span>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.props.item.answer_type === "text" &&
                  this.props.status === "undone" ? (
                    <>
                      <span className="w-100 text-center mb-2">
                        <input
                          onChange={this.setInput}
                          id="text"
                          name="text"
                          className="form-control form-control-sm  border-success bg-dark text-white"
                          type="text"
                          placeholder="Enter your answer... "
                        />
                        <span className="fs-6 text-success" id="suc"></span>
                        <span className="fs-6 text-danger" id="err"></span>
                        <button
                          onClick={() => {
                            this.handleSubText();
                          }}
                          id="text-btn"
                          className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 w-100"
                        >
                          Submit
                        </button>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.props.item.answer_type === "img" &&
                  this.props.status === "undone" ? (
                    <>
                      <span className="btn btn-primary btn-file">
                        Browse...
                        <input type="file" onChange={this.onFileChange} />
                      </span>
                      <button
                        onClick={() => {
                          this.handleUpload();
                        }}
                        id="img-btn"
                        className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 w-100"
                      >
                        Upload
                      </button>
                      <span className="text-muted fs-7 fw-bold">Max 5.0MB</span>
                      <span className="fs-6 text-success" id="file-suc"></span>
                      <span className="fs-6 text-success" id="suc"></span>
                      <span className="fs-6 text-danger" id="err"></span>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.props.status === "fail" ? (
                    <>
                      {this.props.item.answer_type === "link" ? (
                        <>
                          <span className="fs-8  p-1 text-light w-fit mb-2">
                            {" "}
                            Submission:{" "}
                            {this.timeGenarator(this.props.answer.Time)}
                          </span>
                          <span>
                            Link:{" "}
                            <a
                              rel="noopener noreferrer"
                              href={this.props.answer.answer}
                              target="_blank"
                              className="not-underline fs-7 text-muted"
                            >
                              {`${this.props.answer.answer.substring(
                                0,
                                15
                              )}...`}
                            </a>
                          </span>
                          {this.props.answer.msg !== null ? (
                            <>
                              <hr className="divider"></hr>
                              <span className="text-danger">
                                {" "}
                                {this.props.answer.msg}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.props.answer.review_Time !== null ? (
                            <>
                              {" "}
                              <hr className="divider"></hr>
                              <span className="fs-8  p-1 text-light w-fit mb-2">
                                {" "}
                                Review:{" "}
                                {this.timeGenarator(
                                  this.props.answer.review_Time
                                )}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <span className="text-center">
                            <span className="badge text-bg-danger">
                              Rejected
                            </span>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.item.answer_type === "img" ? (
                        <>
                          <span className="fs-8  p-1 text-light w-fit mb-2">
                            {" "}
                            Submission:{" "}
                            {this.timeGenarator(this.props.answer.Time)}
                          </span>
                          <span>
                            Image:{" "}
                            <img
                              alt="AKU ANSWER"
                              className="me-2 answer-image border-danger"
                              src={`${this.path}${this.props.answer.answer}`}
                            />
                          </span>
                          {this.props.answer.msg !== null ? (
                            <>
                              <hr className="divider"></hr>
                              <span className="text-danger">
                                {" "}
                                {this.props.answer.msg}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.props.answer.review_Time !== null ? (
                            <>
                              {" "}
                              <hr className="divider"></hr>
                              <span className="fs-8  p-1 text-light w-fit mb-2">
                                {" "}
                                Review:{" "}
                                {this.timeGenarator(
                                  this.props.answer.review_Time
                                )}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <span className="text-center">
                            <span className="badge text-bg-danger">
                              Rejected
                            </span>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.item.answer_type === "text" ? (
                        <>
                          <span className="fs-8  p-1 text-light w-fit mb-2">
                            {" "}
                            Submission:{" "}
                            {this.timeGenarator(this.props.answer.Time)}
                          </span>
                          <span>Answer: {this.props.answer.answer}</span>
                          {this.props.answer.msg !== null ? (
                            <>
                              <hr className="divider"></hr>
                              <span className="text-danger">
                                {" "}
                                {this.props.answer.msg}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.props.answer.review_Time !== null ? (
                            <>
                              {" "}
                              <hr className="divider"></hr>
                              <span className="fs-8  p-1 text-light w-fit mb-2">
                                {" "}
                                Review:{" "}
                                {this.timeGenarator(
                                  this.props.answer.review_Time
                                )}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <span className="text-center">
                            <span className="badge text-bg-danger">
                              Rejected
                            </span>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="divider"></hr>
                      <div>Try again</div>
                      {this.props.item.answer_type === "link" ? (
                        <>
                          <span className="w-100 text-center mb-2">
                            <input
                              onChange={this.setInput}
                              id="link"
                              name="link"
                              className="form-control form-control-sm border-success bg-dark text-white"
                              type="text"
                              placeholder="https://twitter.com/..."
                            />
                            <span className="fs-6 text-success" id="suc"></span>
                            <span className="fs-6 text-danger" id="err"></span>
                            <button
                              onClick={() => {
                                this.handleEditLink();
                              }}
                              id="link-btn"
                              className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 w-100"
                            >
                              Submit
                            </button>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}

                      {this.props.item.answer_type === "text" ? (
                        <>
                          <span className="w-100 text-center mb-2">
                            <input
                              onChange={this.setInput}
                              id="text"
                              name="text"
                              className="form-control form-control-sm  border-success bg-dark text-white"
                              type="text"
                              placeholder="Enter your answer... "
                            />
                            <span className="fs-6 text-success" id="suc"></span>
                            <span className="fs-6 text-danger" id="err"></span>
                            <button
                              onClick={() => {
                                this.handleEditText();
                              }}
                              id="text-btn"
                              className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 w-100"
                            >
                              Submit
                            </button>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.item.answer_type === "img" ? (
                        <>
                          <span className="btn btn-primary btn-file">
                            Browse...
                            <input type="file" onChange={this.onFileChange} />
                          </span>
                          <button
                            onClick={() => {
                              this.handleEditUpload();
                            }}
                            id="img-btn"
                            className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 w-100"
                          >
                            Upload
                          </button>
                          <span className="text-muted fs-7 fw-bold">
                            Max 5.0MB
                          </span>
                          <span
                            className="fs-6 text-success"
                            id="file-suc"
                          ></span>
                          <span className="fs-6 text-success" id="suc"></span>
                          <span className="fs-6 text-danger" id="err"></span>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {this.props.status === "passed" ? (
                    <>
                      {this.props.item.answer_type === "link" ? (
                        <>
                          <span className="fs-8  p-1 text-light w-fit mb-2">
                            {" "}
                            Submission:{" "}
                            {this.timeGenarator(this.props.answer.Time)}
                          </span>
                          <span>
                            Link:{" "}
                            <a
                              rel="noopener noreferrer"
                              href={this.props.answer.answer}
                              target="_blank"
                              className="not-underline fs-7 text-success"
                            >
                              {`${this.props.answer.answer.substring(
                                0,
                                15
                              )}...`}
                            </a>
                          </span>
                          {this.props.answer.msg !== null ? (
                            <>
                              <hr className="divider"></hr>
                              <span className="text-success">
                                {" "}
                                {this.props.answer.msg}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.props.answer.review_Time !== null ? (
                            <>
                              {" "}
                              <hr className="divider"></hr>
                              <span className="fs-8  p-1 text-light w-fit mb-2">
                                {" "}
                                Review:{" "}
                                {this.timeGenarator(
                                  this.props.answer.review_Time
                                )}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <span className="text-center">
                            <span className="badge text-bg-success">
                              Quest Completed
                            </span>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.item.answer_type === "img" ? (
                        <>
                          <span className="fs-8  p-1 text-light w-fit mb-2">
                            {" "}
                            Submission:{" "}
                            {this.timeGenarator(this.props.answer.Time)}
                          </span>
                          <span>
                            Image:{" "}
                            <img
                              alt="AKU ANSWER"
                              className="me-2 answer-image border-success"
                              src={`${this.path}${this.props.answer.answer}`}
                            />
                          </span>
                          {this.props.answer.msg !== null ? (
                            <>
                              <hr className="divider"></hr>
                              <span className="text-success">
                                {" "}
                                {this.props.answer.msg}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.props.answer.review_Time !== null ? (
                            <>
                              {" "}
                              <hr className="divider"></hr>
                              <span className="fs-8  p-1 text-light w-fit mb-2">
                                {" "}
                                Review:{" "}
                                {this.timeGenarator(
                                  this.props.answer.review_Time
                                )}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <span className="text-center">
                            <span className="badge text-bg-success">
                              Quest Completed
                            </span>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.item.answer_type === "text" ? (
                        <>
                          <span className="fs-8  p-1 text-light w-fit mb-2">
                            {" "}
                            Submission:{" "}
                            {this.timeGenarator(this.props.answer.Time)}
                          </span>
                          <span>
                            Answer:{" "}
                            <span className="text-success">
                              {this.props.answer.answer}
                            </span>
                          </span>
                          {this.props.answer.msg !== null ? (
                            <>
                              <hr className="divider"></hr>
                              <span className="text-success">
                                {" "}
                                {this.props.answer.msg}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.props.answer.review_Time !== null ? (
                            <>
                              {" "}
                              <hr className="divider"></hr>
                              <span className="fs-8  p-1 text-light w-fit mb-2">
                                {" "}
                                Review:{" "}
                                {this.timeGenarator(
                                  this.props.answer.review_Time
                                )}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}

                          <span className="text-center">
                            <span className="badge text-bg-success">
                              Quest Completed
                            </span>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.item.answer_type === null ? (
                        <>
                          <span className="fs-8  p-1 text-light w-fit mb-2">
                            {" "}
                            Submission:{" "}
                            {this.timeGenarator(this.props.answer.Time)}
                          </span>
                          <span className="text-center">
                            <span className="badge text-bg-success">
                              Quest Completed
                            </span>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {this.props.status === "pending" ? (
                    <>
                      {this.props.item.answer_type === "link" ? (
                        <>
                          <span className="fs-8  p-1 text-light w-fit mb-2">
                            {" "}
                            Submission:{" "}
                            {this.timeGenarator(this.props.answer.Time)}
                          </span>
                          <span>
                            Link:{" "}
                            <a
                              rel="noopener noreferrer"
                              href={this.props.answer.answer}
                              target="_blank"
                              className="not-underline fs-7 text-muted"
                            >
                              {`${this.props.answer.answer.substring(
                                0,
                                15
                              )}...`}
                            </a>
                          </span>
                          {this.props.answer.msg !== null ? (
                            <>
                              <hr className="divider"></hr>
                              <span className="text-warning">
                                {" "}
                                {this.props.answer.msg}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.props.answer.review_Time !== null ? (
                            <>
                              {" "}
                              <hr className="divider"></hr>
                              <span className="fs-8  p-1 text-light w-fit mb-2">
                                {" "}
                                Review:{" "}
                                {this.timeGenarator(
                                  this.props.answer.review_Time
                                )}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <span className="text-center">
                            <span className="badge text-bg-warning">
                              In Review
                            </span>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.item.answer_type === "img" ? (
                        <>
                          <span className="fs-8  p-1 text-light w-fit mb-2">
                            {" "}
                            Submission:{" "}
                            {this.timeGenarator(this.props.answer.Time)}
                          </span>
                          <span>
                            Image:{" "}
                            <img
                              alt="AKU ANSWER"
                              className="me-2 answer-image border-warning"
                              src={`${this.path}${this.props.answer.answer}`}
                            />
                          </span>
                          {this.props.answer.msg !== null ? (
                            <>
                              <hr className="divider"></hr>
                              <span className="text-warning">
                                {" "}
                                {this.props.answer.msg}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.props.answer.review_Time !== null ? (
                            <>
                              {" "}
                              <hr className="divider"></hr>
                              <span className="fs-8  p-1 text-light w-fit mb-2">
                                {" "}
                                Review:{" "}
                                {this.timeGenarator(
                                  this.props.answer.review_Time
                                )}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <span className="text-center">
                            <span className="badge text-bg-warning">
                              In Review
                            </span>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.item.answer_type === "text" ? (
                        <>
                          <span className="fs-8  p-1 text-light w-fit mb-2">
                            {" "}
                            Submission:{" "}
                            {this.timeGenarator(this.props.answer.Time)}
                          </span>
                          <span>Answer: {this.props.answer.answer}</span>
                          {this.props.answer.msg !== null ? (
                            <>
                              <hr className="divider"></hr>
                              <span className="text-warning">
                                {" "}
                                {this.props.answer.msg}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.props.answer.review_Time !== null ? (
                            <>
                              {" "}
                              <hr className="divider"></hr>
                              <span className="fs-8  p-1 text-light w-fit mb-2">
                                {" "}
                                Review:{" "}
                                {this.timeGenarator(
                                  this.props.answer.review_Time
                                )}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <span className="text-center">
                            <span className="badge text-bg-warning">
                              In Review
                            </span>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6  h-100 p-2 mt-2   ">
                <div className="d-flex flex-column border border-1 p-2 BR-5">
                  <span className="fw-bold fs-5">Reward</span>
                  <span>
                    <span className="badge text-bg-dark me-1 min-h-30 justify-content-start d-flex align-items-center">
                      <img
                        alt="AKU XP"
                        className=""
                        width="20"
                        height="20"
                        src="./asset/Menu/xp.png"
                      />
                      <span className="fs-7 ms-1 fw-bold">
                        {this.props.item.xp}
                      </span>
                    </span>
                  </span>
                  {this.props.item.score !== 0 ? (
                    <>
                      <span>
                        <span className="badge text-bg-dark me-1 min-h-30 justify-content-start d-flex align-items-center">
                          <img
                            alt="AKU POINT"
                            className=""
                            width="20"
                            height="20"
                            src="./asset/logo/point.png"
                          />
                          <span className="fs-7 ms-1 fw-bold">
                            {this.props.item.score} POINT
                          </span>
                        </span>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default OnceQuestItem;
