import React, { Component } from "react";
import SentItem from "./SentItem";
class Sent extends Component {
 
  state = {};
  render() {

    return (
      <>
        {" "}
        <div className="col-12 col-md-10 px-2 h-msg">
          {this.props.userSentMessage.length > 0 ? (
            <>
              <div className="row align-self-center g-1 w-100">
                <div className="col-12 border border-1 BR-5 mt-2 p-0 border-white-05">
                  <div className="row align-self-center g-1 w-100 p-0 h-100">
                    <div className="col-3 msg-item h-100 d-flex align-items-center justify-content-center text-center fw-bolder text-success">
                      <span>To</span>
                    </div>
                    <div className="col-3 msg-item h-100 d-flex align-items-center justify-content-center text-center fw-bolder text-success">
                      Subject
                    </div>
                    <div className="col-3 msg-item h-100 d-flex align-items-center justify-content-center text-center fs-7 fw-bolder text-success">
                      Message
                    </div>
                    <div className="col-1 msg-item fs-8 h-100 d-flex align-items-center justify-content-center text-center fw-bolder text-success">
                      Date
                    </div>
                    <div className="col-2 h-100 d-flex align-items-center justify-content-center text-center fw-bolder text-success">
                      {/* <i
              className="bi bi-envelope-open-fill btn-scale c-pointer text-success"
            ></i>  */}

                      {/* <i className="bi bi-gear-fill"></i> */}
                      <button
                        id="refresh-btn"
                        className="btn btn-transparent border border-0 p-0 "
                        onClick={this.props.refreshMessages}
                      >
                        <i className="bi bi-arrow-clockwise fs-5 text-warning "></i>
                      </button>
                    </div>
                  </div>
                </div>

                {this.props.userSentMessage.map((message) => {
                  return (
                    <SentItem
                    key={message.SM_ID}
                      message={message}
                      refreshMessages={this.props.refreshMessages}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className="row align-self-center g-1 w-100">
                <div className="col-12 fs-4 fw-bold text-muted text-center mt-2 mb-2">
                  Sent is empty
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default Sent;
