import React, { Component } from "react";
import "../../STTwoSTYLE.css";

class SpecialTwoItem extends Component {
  state = {};
  render() {
    return (
      <>
        <div className=" text-center  position-relative h-150px ">
          <div class="table center ">
            <div class="monitor-wrapper center  ">
              <div class="monitor center">
                <p>
                  23 5 <span className="gg"> &nbsp; </span> 1 18 5{" "}
                  <span className="gg"> &nbsp; </span> 6 1 13 9 12 25
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SpecialTwoItem;
