import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "../providers/providerOptions";
import SCABI from "../smartContractABI/ABI.json";
import backdround from "../images/background/back.gif";
import Menu from "./Menu";
import Footer from "./Footer";
import Home from "./Home";
import Signup from "./SignUp";
import Quests from "./Quests";
import Messages from "./Messages";
import About from "./About";
import Leaderboard from "./Leaderboard";
import Setting from "./Setting";
import Mint from "./Mint";
import Loading from "./Loading";
import Convert from "./Convert";
import ChatGPT from "./ChatGPT";
const SCAddress = "0x71AA3598C7577943FE17B756874271A77c9Db34B";
const web3Modal = new Web3Modal({
  cacheProvider: false, // optional
  providerOptions, // required
  theme: "dark",
  disableInjectedProvider: false,
});

const Main = () => {
  const [accounts, setAccounts] = useState([]);
  const [user, setUser] = useState([]);
  const [dailyQuests, setDailyQuests] = useState();
  const [dailyQuestNotif, setDailyQuestNotif] = useState();
  const [weeklyQuestNotif, setWeeklyQuestNotif] = useState();
  const [specialQuestNotif, setSpecialQuestNotif] = useState();
  const [notif, setNotif] = useState();
  const [missionNotif, setMissionNotif] = useState();
  const [dailyQuestsPass, setDailyQuestsPass] = useState({});
  const [specialQuests, setSpecialQuests] = useState();
  const [specialQuestsPass, setSpecialQuestsPass] = useState({});
  const [missionPass, setMissionPass] = useState({});
  const [weeklyQuests, setWeeklyQuests] = useState();
  const [weeklyQuestsPass, setWeeklyQuestsPass] = useState({});
  const [userReqHistory, setUserReqHistory] = useState();
  const [userMessage, setUserMessage] = useState();
  const [userSentMessage, setUserSentMessage] = useState();
  // eslint-disable-next-line
  const [userMessageStatus, setUserMessageStatus] = useState();
  const [broadcast, setBroadcast] = useState();
  const [broadcastStatus, setBroadcastStatus] = useState();
  const [newBroadcast, setNewBroadcat] = useState();
  const [newUserMessage, setNewUserMessage] = useState();
  const [isMember, setIsMember] = useState(false);
  const [forceSetting, setForceSetting] = useState(false);
  const [akuCount, setAkuCount] = useState(0);
  const [ukaCount, setUkaCount] = useState(0);
  const [rank, setRank] = useState(0);
  const [dataIsGet, setDataIsGet] = useState(false);
  // eslint-disable-next-line
  const [delay, setDelay] = useState(false);
  const [users, setUsers] = useState();
  const [holders, setHolders] = useState();
  const [usersAkuCount, setUsersAkuCount] = useState();
  const [userTokenList, setUserTokenList] = useState([]);
  // eslint-disable-next-line
  const [userTokenMetadata, setUserTokenMetadata] = useState([]);
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  // eslint-disable-next-line
  const [error, setError] = useState("");
  // eslint-disable-next-line
  const [chainId, setChainId] = useState();
  const [homePage, setHomePage] = useState(true);
  const [signupPage, setSignupPage] = useState(false);
  const [questsPage, setQuestsPage] = useState(false);
  const [messagesPage, setMessagesPage] = useState(false);
  const [aboutPage, setAboutPage] = useState(false);
  const [leaderboardPage, setLeaderboardPage] = useState(false);
  const [settingPage, setSettingPage] = useState(false);
  const [convertPage, setConvertPage] = useState(false);
  const [mintPage, setMintPage] = useState(false);
  const [chatgptPage, setChatgptPage] = useState(false);
  const isConnected = Boolean(accounts[0]);
  const refreshPage = () => {
    if (questsPage) {
      document.body.style.backgroundImage = ` url('${backdround}')`;
    }
    if (forceSetting) {
      setForceSetting(false);
    }
    setHomePage(false);
    setSignupPage(false);
    setQuestsPage(false);
    setMessagesPage(false);
    setAboutPage(false);
    setLeaderboardPage(false);
    setSettingPage(false);
    setConvertPage(false);
    setMintPage(false);
    setChatgptPage(false);
  };
  const handleHomePage = () => {
    refreshPage();
    setHomePage(true);
  };
  const handleSignupPage = () => {
    refreshPage();
    setSignupPage(!signupPage);
  };
  const handleQuestsPage = () => {
    document.body.style.backgroundImage = ` url('')`;

    refreshPage();
    setQuestsPage(!questsPage);
  };

  const handleMessagesPage = () => {
    refreshPage();
    setMessagesPage(!messagesPage);
  };
  const handleAboutPage = () => {
    refreshPage();
    setAboutPage(!aboutPage);
  };
  const handleConvertPage = () => {
    refreshPage();
    setConvertPage(!convertPage);
  };
  const handleLeaderboardPage = () => {
    refreshPage();
    setLeaderboardPage(!leaderboardPage);
  };
  const handleSettingPage = () => {
    refreshPage();
    setSettingPage(!settingPage);
  };
  const handleMintPage = () => {
    refreshPage();
    setMintPage(!mintPage);
  };
  const handleChatgptPage = () => {
    console.log(chatgptPage);
    refreshPage();
    setChatgptPage(!chatgptPage);
  };
  const getRank = (w, u) => {
    const index = u
      .map((e) => {
        return e.wallet_address.toLowerCase();
      })
      .indexOf(w.toLowerCase());
    var temp = index;

    temp++;

    setRank(temp);
  };

  useEffect(() => {
    GetUsers(false, "");
    getHolders();
  }, []);
  const UpdateNFTcount = async (w, c) => {
    const get = await fetch("https://api.the369.xyz/UpdateNFTcount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        NFTcount: c,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
    }
    if (get.status === "failed") {
    }
  };
  const unsuspend = async (w) => {
    const get = await fetch("https://api.the369.xyz/unsuspend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      GetUsers(true, w);
    }
    if (get.status === "failed") {
    }
  };

  const GetUserTokens = async (w, l) => {
    const contract = new ethers.Contract(SCAddress, SCABI, l);
    try {
      const Balance = await contract.balanceOf(w);
      const totalSupply = await contract.totalSupply();
      // eslint-disable-next-line
      const TS = parseInt(String(totalSupply), 10);
      const B = parseInt(String(Balance), 10);

      UpdateNFTcount(w, B);

      CkeckUserTokenId(w).then(async (res) => {
        JSON.stringify(res);
        if (res[0]) {
          //State 2

          var tokenIds = [];
          for (var e = 0; e < res.length; e++) {
            tokenIds.push(res[e].token_id);
          }
          setUserTokenList(tokenIds);
          var tokenIdMetadata = [];
          var akuCount = 0;
          var ukaCount = 0;
          for (var t = 0; t < tokenIds.length; t++) {
            const temp = await fetch(`./NFTJSONs/${tokenIds[t]}.json`).then(
              (res) => res.json()
            );

            tokenIdMetadata.push(temp);
            if (temp.attributes[8].value === "1 11 21  1 11 21") {
              akuCount++;
            }
            if (temp.attributes[8].value === "21 11 1  21 11 1") {
              ukaCount++;
            }
          }

          setUserTokenMetadata(tokenIdMetadata);
          setAkuCount(akuCount);
          setUkaCount(ukaCount);
          setDataIsGet(true);
        } else {
          //State 1

          setAkuCount(0);
          setUkaCount(0);
          setDataIsGet(true);
        }
      });
    } catch (err) {}
  };
  const insertUserLog = async (w, t) => {
    const get = await fetch("https://api.the369.xyz/insertUserLog", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        twitter: t,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
    }
    if (get.status === "failed") {
    }
  };
  const getSpecialQuestStatus = async (w, q) => {
    const get = await fetch("https://api.the369.xyz/getSpecialQuestStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        quest: q,
      }),
    }).then((res) =>
      res.json().then((data) => {
        var yes = specialQuestsPass;

        if (data[0]) {
          yes[q] = data[0];
        } else {
          yes[q] = { status: "undone" };
        }
        setSpecialQuestsPass(yes);
      })
    );
    return get;
  };
  const getSpecialQuests = async (w) => {
    const get = await fetch("https://api.the369.xyz/getSpecialQuests", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setSpecialQuests(data);

        data.forEach(function (item) {
          getSpecialQuestStatus(w, item.SQ_ID);
        });
      })
    );
    return get;
  };
  const getDailyQuestStatus = async (w, q, d) => {
    const get = await fetch("https://api.the369.xyz/getDailyQuestStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        quest: q,
        day: d,
      }),
    }).then((res) =>
      res.json().then((data) => {
        var fes = dailyQuestsPass;

        if (data[0]) {
          fes[q] = data[0];
        } else {
          fes[q] = { status: "undone" };
        }
        setDailyQuestsPass(fes);
      })
    );
    return get;
  };
  const getDailyQuestNotif = async (w) => {
    const get = await fetch("https://api.the369.xyz/getDailyQuestsNotif", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) =>
      res.json().then((data) => {
        setDailyQuestNotif(data);
      })
    );
    return get;
  };
  const getWeeklyQuestNotif = async (w) => {
    const get = await fetch("https://api.the369.xyz/getWeeklyQuestsNotif", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) =>
      res.json().then((data) => {
        setWeeklyQuestNotif(data);
      })
    );
    return get;
  };
  const getNotif = async (w) => {
    const get = await fetch("https://api.the369.xyz/getNotif", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) =>
      res.json().then((data) => {
        setNotif(data);
      })
    );
    return get;
  };
  const getMissionNotif = async (w) => {
    const get = await fetch("https://api.the369.xyz/getMissionNotif", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) =>
      res.json().then((data) => {
        setMissionNotif(data);
        var hes = [];
        data.forEach(function (item) {
          hes.push(item.mission_id);
        });
        setMissionPass(hes);
      })
    );
    return get;
  };
  const getSpecialQuestNotif = async (w) => {
    const get = await fetch("https://api.the369.xyz/getSpecialQuestsNotif", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) =>
      res.json().then((data) => {
        setSpecialQuestNotif(data);
      })
    );
    return get;
  };
  const getDailyQuests = async (w) => {
    const get = await fetch("https://api.the369.xyz/getDailyQuests", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setDailyQuests(data);

        data.forEach(function (item) {
          getDailyQuestStatus(w, item.DQ_ID, item.day);
        });
      })
    );
    return get;
  };
  const getWeeklyQuestStatus = async (w, q, we) => {
    const get = await fetch("https://api.the369.xyz/getWeeklyQuestStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        quest: q,
        week: we,
      }),
    }).then((res) =>
      res.json().then((data) => {
        var les = weeklyQuestsPass;

        if (data[0]) {
          les[q] = data[0];
        } else {
          les[q] = { status: "undone" };
        }
        setWeeklyQuestsPass(les);
      })
    );
    return get;
  };
  const getWeeklyQuests = async (w) => {
    const get = await fetch("https://api.the369.xyz/getWeeklyQuests", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setWeeklyQuests(data);

        data.forEach(function (item) {
          getWeeklyQuestStatus(w, item.WQ_ID, item.week);
        });
      })
    );
    return get;
  };
  const getUserSentMessage = async (w) => {
    const get = await fetch("https://api.the369.xyz/getUserSentMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) =>
      res.json().then((data) => {
        setUserSentMessage(data);
      })
    );
    return get;
  };
  const getUserMessage = async (w) => {
    const get = await fetch("https://api.the369.xyz/getUserMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) =>
      res.json().then((data) => {
        var tes = {};
        data.forEach(function (item) {
          tes[item.status] ? tes[item.status]++ : (tes[item.status] = 1);
        });
        if (!tes.unread) {
          tes.unread = 0;
        }
        setUserMessage(data);
        setUserMessageStatus(tes);
        setNewUserMessage(tes.unread);
      })
    );
    return get;
  };
  const getBroadcast = async (w) => {
    const get = await fetch("https://api.the369.xyz/getBroadcast", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        getBroadcastStatus(w, data);
        setBroadcast(data);
      })
    );
    return get;
  };
  const getBroadcastStatus = async (w, B) => {
    const get = await fetch("https://api.the369.xyz/getBroadcastStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) =>
      res.json().then((data) => {
        var bes = {};
        var hes = 0;
        B.forEach(function (item) {
          const index = data
            .map((e) => {
              return e.msg_id;
            })
            .indexOf(item.BM_ID);
          if (index !== -1) {
            bes[item.BM_ID] = "read";
          } else {
            bes[item.BM_ID] = "unread";
            hes++;
          }
        });
        setBroadcastStatus(bes);
        setNewBroadcat(hes);
      })
    );
    return get;
  };
  const refreshMessages = () => {
    getUserMessage(accounts[0]);
    getBroadcast(accounts[0]);
    getUserSentMessage(accounts[0]);
  };
  const getHolders = async () => {
    const get = await fetch("https://api.the369.xyz/akuakuCounter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        var rez = {};
        data.forEach(function (item) {
          rez[item.wallet_address]
            ? rez[item.wallet_address]++
            : (rez[item.wallet_address] = 1);
        });
        setHolders(data);
        setUsersAkuCount(rez);
      })
    );
    return get;
  };
  const getUserReqHistory = async (w) => {
    const get = await fetch("https://api.the369.xyz/twitterChangeHistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) =>
      res.json().then((data) => {
        setUserReqHistory(data);
      })
    );
    return get;
  };
  const GetUsers = async (controller, w) => {
    const get = await fetch("https://api.the369.xyz/getAllUsers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        if (controller) {
          const index = data
            .map((e) => {
              return e.wallet_address.toLowerCase();
            })
            .indexOf(w.toLowerCase());
          var temp = index;
          temp++;

          setRank(temp);
        }
        setUsers(data);
      })
    );
    return get;
  };

  const CkeckUserTokenId = async (w) => {
    const get = await fetch("https://api.the369.xyz/checkUserTokenId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) => res.json());
    return get;
  };
  const refreshQuests = () => {
    console.log("ttttttttttttttt");
    getDailyQuestNotif(accounts[0]);
    getWeeklyQuestNotif(accounts[0]);
    getSpecialQuestNotif(accounts[0]);
    getNotif(accounts[0]);
    getMissionNotif(accounts[0]);
    getDailyQuests(accounts[0]);
    getWeeklyQuests(accounts[0]);
    getSpecialQuests(accounts[0]);
  };
  const updateUser = () => {
    GetUsers(false, "");
    getHolders();

    GetFromDB(accounts[0]).then((res) => {
      JSON.stringify(res);
      if (res[0]) {
        setUser(res[0]);
        setIsMember(true);
        getDailyQuestNotif(res[0].wallet_address);
        getWeeklyQuestNotif(res[0].wallet_address);
        getSpecialQuestNotif(res[0].wallet_address);
        getNotif(res[0].wallet_address);
        getMissionNotif(res[0].wallet_address);
        getDailyQuests(res[0].wallet_address);
        getWeeklyQuests(res[0].wallet_address);
        getSpecialQuests(res[0].wallet_address);
        getUserReqHistory(res[0].wallet_address);
        getUserMessage(res[0].wallet_address);
        getBroadcast(res[0].wallet_address);
        getUserSentMessage(res[0].wallet_address);

        if (res[0].isSuspended === 1) {
          unsuspend(res[0].wallet_address);
          setForceSetting(true);
          handleSettingPage();
        } else {
          getRank(res[0].wallet_address, users);
        }
      } else {
        setUser([]);
        setIsMember(false);
        handleSignupPage();
      }
    });
    getUserReqHistory(accounts[0]);
  };
  const GetFromDB = async (w) => {
    const get = await fetch("https://api.the369.xyz/checkUserExist", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) => res.json());
    return get;
  };
  const handleconnect = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const wallets = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      handleHomePage();
      if (wallets) setAccounts(wallets);
      if (network.chainId !== 1) {
        alert(
          "Your network is not on Ethereum Mainnet, Change your network and connect again. "
        );
        disconnect();
      } else {
        GetFromDB(wallets[0]).then((res) => {
          JSON.stringify(res);
          if (res[0]) {
            insertUserLog(res[0].wallet_address, res[0].Twitter_Id);
            setUser(res[0]);
            setIsMember(true);
            getDailyQuestNotif(res[0].wallet_address);
            getWeeklyQuestNotif(res[0].wallet_address);
            getSpecialQuestNotif(res[0].wallet_address);
            getNotif(res[0].wallet_address);
            getMissionNotif(res[0].wallet_address);
            getDailyQuests(res[0].wallet_address);
            getWeeklyQuests(res[0].wallet_address);
            getSpecialQuests(res[0].wallet_address);
            getUserReqHistory(res[0].wallet_address);
            getUserMessage(res[0].wallet_address);
            getBroadcast(res[0].wallet_address);
            getUserSentMessage(res[0].wallet_address);

            if (res[0].isSuspended === 1) {
              unsuspend(res[0].wallet_address);
              setForceSetting(true);
              handleSettingPage();
            } else {
              getRank(res[0].wallet_address, users);
            }
          } else {
            insertUserLog(wallets[0], null);
            setUser([]);
            setIsMember(false);
            handleSignupPage();
          }
        });
        GetUserTokens(wallets[0], library);
      }
      setChainId(network.chainId);
    } catch (error) {
      setError(error);
    }
  };
  // eslint-disable-next-line
  const refreshState = () => {
    setAccounts([]);
  };

  const disconnect = () => {
    web3Modal.clearCachedProvider();
    setAccounts([]);
    setIsMember(false);
    if (!homePage) {
      handleHomePage();
    }
  };

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (wallets) => {
        if (wallets) {
          handleHomePage();
          setAccounts(wallets);
          GetFromDB(wallets[0]).then((res) => {
            JSON.stringify(res);
            if (res[0]) {
              insertUserLog(res[0].wallet_address, res[0].Twitter_Id);
              setUser(res[0]);
              setIsMember(true);
              getDailyQuestNotif(res[0].wallet_address);
              getWeeklyQuestNotif(res[0].wallet_address);
              getSpecialQuestNotif(res[0].wallet_address);
              getNotif(res[0].wallet_address);
              getMissionNotif(res[0].wallet_address);
              getDailyQuests(res[0].wallet_address);
              getWeeklyQuests(res[0].wallet_address);
              getSpecialQuests(res[0].wallet_address);
              getUserReqHistory(res[0].wallet_address);
              getUserMessage(res[0].wallet_address);
              getBroadcast(res[0].wallet_address);
              getUserSentMessage(res[0].wallet_address);

              if (res[0].isSuspended === 1) {
                unsuspend(res[0].wallet_address);
                setForceSetting(true);
                handleSettingPage();
              } else {
                getRank(res[0].wallet_address, users);
              }
            } else {
              insertUserLog(wallets[0], null);
              setUser([]);
              setIsMember(false);
              handleSignupPage();
            }
          });
          GetUserTokens(wallets[0], library);
        }
      };

      const handleChainChanged = (_hexChainId) => {
        if (parseInt(_hexChainId) !== 1) {
          alert(
            "Your network is not on Ethereum Mainnet, Change your network and connect again. "
          );
          disconnect();
        }
        setChainId(_hexChainId);
      };

      const handleDisconnect = () => {
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
    // eslint-disable-next-line
  }, [provider]);

  if (isConnected && !dataIsGet) {
    return (
      <>
        <Loading delay={delay} />
      </>
    );
  }
  if (
    homePage &&
    !signupPage &&
    !questsPage &&
    !messagesPage &&
    !aboutPage &&
    !leaderboardPage &&
    !settingPage &&
    !mintPage &&
    !convertPage &&
    !chatgptPage
  ) {
    return (
      <>
        <Menu
          location={"home"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          NFTCount={akuCount + ukaCount}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleSignupPage={handleSignupPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleAboutPage={handleAboutPage}
          handleLeaderboardPage={handleLeaderboardPage}
          handleSettingPage={handleSettingPage}
          handleMintPage={handleMintPage}
          handleConvertPage={handleConvertPage}
          handleChatgptPage={handleChatgptPage}
          rank={rank}
          newMessage={newBroadcast + newUserMessage}
        />
        <Home />
        <Footer />
      </>
    );
  }
  if (
    !homePage &&
    signupPage &&
    !questsPage &&
    !messagesPage &&
    !aboutPage &&
    !leaderboardPage &&
    !settingPage &&
    !mintPage &&
    !convertPage &&
    !chatgptPage
  ) {
    return (
      <>
        <Menu
          location={"signup"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          NFTCount={akuCount + ukaCount}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleSignupPage={handleSignupPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleAboutPage={handleAboutPage}
          handleLeaderboardPage={handleLeaderboardPage}
          handleSettingPage={handleSettingPage}
          handleMintPage={handleMintPage}
          handleConvertPage={handleConvertPage}
          handleChatgptPage={handleChatgptPage}
          rank={rank}
          newMessage={newBroadcast + newUserMessage}
        />
        <Signup
          handleHomePage={handleHomePage}
          NFTCount={akuCount + ukaCount}
          wallet={accounts[0]}
          updateUser={() => {
            updateUser();
            handleHomePage();
          }}
        />{" "}
        <Footer />
      </>
    );
  }
  if (
    !homePage &&
    !signupPage &&
    questsPage &&
    !messagesPage &&
    !aboutPage &&
    !leaderboardPage &&
    !settingPage &&
    !mintPage &&
    !convertPage &&
    !chatgptPage
  ) {
    return (
      <>
        <Menu
          location={"quests"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          NFTCount={akuCount + ukaCount}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleSignupPage={handleSignupPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleAboutPage={handleAboutPage}
          handleLeaderboardPage={handleLeaderboardPage}
          handleSettingPage={handleSettingPage}
          handleMintPage={handleMintPage}
          handleConvertPage={handleConvertPage}
          handleChatgptPage={handleChatgptPage}
          rank={rank}
          newMessage={newBroadcast + newUserMessage}
        />
        <Quests
          dailyQuestNotif={dailyQuestNotif}
          weeklyQuestNotif={weeklyQuestNotif}
          specialQuestNotif={specialQuestNotif}
          notif={notif}
          missionNotif={missionNotif}
          updateUser={updateUser}
          akuCount={akuCount}
          ukaCount={ukaCount}
          user={user}
          dailyQuests={dailyQuests}
          dailyQuestsPass={dailyQuestsPass}
          weeklyQuests={weeklyQuests}
          weeklyQuestsPass={weeklyQuestsPass}
          specialQuests={specialQuests}
          specialQuestsPass={specialQuestsPass}
          missionPass={missionPass}
          refreshQuests={refreshQuests}
          wallet={accounts[0]}
        />{" "}
        <Footer />
      </>
    );
  }

  if (
    !homePage &&
    !signupPage &&
    !questsPage &&
    messagesPage &&
    !aboutPage &&
    !leaderboardPage &&
    !settingPage &&
    !mintPage &&
    !convertPage &&
    !chatgptPage
  ) {
    return (
      <>
        <Menu
          location={"messages"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          NFTCount={akuCount + ukaCount}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleSignupPage={handleSignupPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleAboutPage={handleAboutPage}
          handleLeaderboardPage={handleLeaderboardPage}
          handleSettingPage={handleSettingPage}
          handleMintPage={handleMintPage}
          handleConvertPage={handleConvertPage}
          handleChatgptPage={handleChatgptPage}
          rank={rank}
          newMessage={newBroadcast + newUserMessage}
        />
        <Messages
          user={user}
          userSentMessage={userSentMessage}
          userMessage={userMessage}
          broadcast={broadcast}
          broadcastStatus={broadcastStatus}
          refreshMessages={refreshMessages}
          wallet={accounts[0]}
        />{" "}
        <Footer />
      </>
    );
  }
  if (
    !homePage &&
    !signupPage &&
    !questsPage &&
    !messagesPage &&
    aboutPage &&
    !leaderboardPage &&
    !settingPage &&
    !mintPage &&
    !convertPage &&
    !chatgptPage
  ) {
    return (
      <>
        <Menu
          location={"about"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          NFTCount={akuCount + ukaCount}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleSignupPage={handleSignupPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleAboutPage={handleAboutPage}
          handleLeaderboardPage={handleLeaderboardPage}
          handleSettingPage={handleSettingPage}
          handleMintPage={handleMintPage}
          handleConvertPage={handleConvertPage}
          handleChatgptPage={handleChatgptPage}
          rank={rank}
          newMessage={newBroadcast + newUserMessage}
        />
        <About /> <Footer />
      </>
    );
  }
  if (
    !homePage &&
    !signupPage &&
    !questsPage &&
    !messagesPage &&
    !aboutPage &&
    leaderboardPage &&
    !settingPage &&
    !mintPage &&
    !convertPage &&
    !chatgptPage
  ) {
    return (
      <>
        <Menu
          location={"leaderboard"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          NFTCount={akuCount + ukaCount}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleSignupPage={handleSignupPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleAboutPage={handleAboutPage}
          handleLeaderboardPage={handleLeaderboardPage}
          handleSettingPage={handleSettingPage}
          handleMintPage={handleMintPage}
          handleConvertPage={handleConvertPage}
          handleChatgptPage={handleChatgptPage}
          rank={rank}
          newMessage={newBroadcast + newUserMessage}
        />
        <Leaderboard
          users={users}
          user={user}
          rank={rank}
          isConnected={isConnected}
          isMember={isMember}
          wallet={accounts[0]}
          holders={holders}
          usersAkuCount={usersAkuCount}
        />{" "}
        <Footer />
      </>
    );
  }
  if (
    !homePage &&
    !signupPage &&
    !questsPage &&
    !messagesPage &&
    !aboutPage &&
    !leaderboardPage &&
    settingPage &&
    !mintPage &&
    !convertPage &&
    !chatgptPage
  ) {
    return (
      <>
        <Menu
          location={"setting"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          NFTCount={akuCount + ukaCount}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleSignupPage={handleSignupPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleAboutPage={handleAboutPage}
          handleLeaderboardPage={handleLeaderboardPage}
          handleSettingPage={handleSettingPage}
          handleMintPage={handleMintPage}
          handleConvertPage={handleConvertPage}
          handleChatgptPage={handleChatgptPage}
          rank={rank}
          newMessage={newBroadcast + newUserMessage}
        />
        <Setting
          forceSetting={forceSetting}
          userTokenList={userTokenList}
          wallet={accounts[0]}
          user={user}
          updateUser={updateUser}
          userReqHistory={userReqHistory}
          disconnect={disconnect}
        />{" "}
        <Footer />
      </>
    );
  }
  if (
    !homePage &&
    !signupPage &&
    !questsPage &&
    !messagesPage &&
    !aboutPage &&
    !leaderboardPage &&
    !settingPage &&
    mintPage &&
    !convertPage &&
    !chatgptPage
  ) {
    return (
      <>
        <Menu
          location={"mint"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          NFTCount={akuCount + ukaCount}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleSignupPage={handleSignupPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleAboutPage={handleAboutPage}
          handleLeaderboardPage={handleLeaderboardPage}
          handleSettingPage={handleSettingPage}
          handleMintPage={handleMintPage}
          handleConvertPage={handleConvertPage}
          handleChatgptPage={handleChatgptPage}
          rank={rank}
          newMessage={newBroadcast + newUserMessage}
        />
        <Mint /> <Footer />
      </>
    );
  }
  if (
    !homePage &&
    !signupPage &&
    !questsPage &&
    !messagesPage &&
    !aboutPage &&
    !leaderboardPage &&
    !settingPage &&
    !mintPage &&
    convertPage &&
    !chatgptPage
  ) {
    return (
      <>
        <Menu
          location={"convert"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          NFTCount={akuCount + ukaCount}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleSignupPage={handleSignupPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleAboutPage={handleAboutPage}
          handleLeaderboardPage={handleLeaderboardPage}
          handleSettingPage={handleSettingPage}
          handleMintPage={handleMintPage}
          handleConvertPage={handleConvertPage}
          handleChatgptPage={handleChatgptPage}
          rank={rank}
          newMessage={newBroadcast + newUserMessage}
        />
        <Convert user={user} isMember={isMember} />
        <Footer />
      </>
    );
  }
  if (
    !homePage &&
    !signupPage &&
    !questsPage &&
    !messagesPage &&
    !aboutPage &&
    !leaderboardPage &&
    !settingPage &&
    !mintPage &&
    !convertPage &&
    chatgptPage
  ) {
    return (
      <>
        <Menu
          location={"chatgpt"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          NFTCount={akuCount + ukaCount}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleSignupPage={handleSignupPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleAboutPage={handleAboutPage}
          handleLeaderboardPage={handleLeaderboardPage}
          handleSettingPage={handleSettingPage}
          handleMintPage={handleMintPage}
          handleConvertPage={handleConvertPage}
          handleChatgptPage={handleChatgptPage}
          rank={rank}
          newMessage={newBroadcast + newUserMessage}
        />
        <ChatGPT />
        <Footer />
      </>
    );
  }

  // if (!isConnected) {
  //   return (
  //     <>
  //       <button onClick={handleconnect}>Connect</button>
  //       <br />
  //       {isConnected ? accounts[0] : null}
  //       <br />
  //       {user ? user.Twitter_Id : null}
  //       <br />
  //       {/* {users ? users[0].xp : null} */}
  //       {userTokenList.map((tokenID) => {
  //         return `${tokenID} ${"--"}`;
  //       })}
  //       <br />
  //       {ukaCount !== 0 ? <>Uka Uka : {ukaCount}</> : <></>}
  //       <br />
  //       {akuCount !== 0 ? <>Aku Aku : {akuCount}</> : <></>}
  //       <br />
  //       {isConnected ? (
  //         <>
  //           {isMember ? (
  //             <>You are 1/369</>
  //           ) : (
  //             <>
  //               <SipgnUp
  //                 wallet={accounts[0]}
  //                 akuCount={akuCount}
  //                 ukaCount={ukaCount}
  //                 userTokenList={userTokenList}
  //               />
  //             </>
  //           )}
  //         </>
  //       ) : (
  //         <>Connect PLZ .....</>
  //       )}
  //     </>
  //   );
  // }

  // if (isConnected && dataIsGet) {
  //   return (
  //     <>
  //       {isConnected ? (
  //         <>
  //           {isMember ? (
  //             <>You are 1/369</>
  //           ) : (
  //             <>
  //               <Menu handdleconnect={handleconnect} />
  //               <Home />
  //               {/* <SipgnUp
  //                 wallet={accounts[0]}
  //                 akuCount={akuCount}
  //                 ukaCount={ukaCount}
  //                 userTokenList={userTokenList}
  //               /> */}
  //               <Footer />
  //             </>
  //           )}
  //         </>
  //       ) : (
  //         <>Connect PLZ .....</>
  //       )}
  //     </>
  //   );
  // }
};

export default Main;
