import React, { Component } from "react";
import DailyQuestItem from "./DailyQuestItem";
class DailyQuests extends Component {
  state = {
    temp: this.props.DQpass,
  };
  render() {
    return (
      <>
        {this.props.DailyQuestsList.map((item) => {
          var status;
          var lock = false;
          var lockReason;
          if (this.props.DQpass[item.DQ_ID].status === "undone") {
            status = "undone";
          }
          if (this.props.DQpass[item.DQ_ID].status === "passed") {
            status = "passed";
          }
          if (this.props.DQpass[item.DQ_ID].status === "pending") {
            status = "pending";
          }
          if (this.props.DQpass[item.DQ_ID].status === "fail") {
            status = "fail";
          }
          if (item.pre_score > this.props.user.score) {
            lock = true;
            lockReason = `You need ${
              item.pre_score - this.props.user.score
            } more point to unlock quest`;
          }
          if (item.pre_aku > this.props.akuCount) {
            lock = true;
            lockReason = `You need ${
              item.pre_aku - this.props.akuCount
            } more AkuAku to unlock quest`;
          }
          if (item.pre_uka > this.props.ukaCount) {
            lock = true;
            lockReason = `You need ${
              item.pre_uka - this.props.ukaCount
            } more UkaUka to unlock quest`;
          }
          if (item.pre_nft > this.props.nftCount) {
            lock = true;
            lockReason = `You need ${
              item.pre_nft - this.props.nftCount
            } more AkuAku NFT to unlock quest`;
          }
          if (item.disabled === 0) {
            return (
              <DailyQuestItem
                key={item.DQ_ID}
                user={this.props.user}
                answer={this.props.DQpass[item.DQ_ID]}
                lockReason={lockReason}
                lock={lock}
                status={status}
                item={item}
                wallet={this.props.wallet}
                refreshQuests={this.props.refreshQuests}
                updateuser={this.props.updateUser}
              />
            );
          } else {
            return null;
          }
        })}
      </>
    );
  }
}

export default DailyQuests;
