import React, { Component } from "react";
class About extends Component {
  state = {};

  render() {
    return (
      <>
        <div className="min-vh-100 work d-flex justify-content-center align-self-center">
          <div className="min-h-work d-flex justify-content-center align-self-center w-100">
            <div className="row m-3 justify-content-evenly align-self-center w-100">
              <div className="col-12 mt-3 h-400 div-work d-flex justify-content-center max-w-992">
                <span className="text-center c-reg">
                  <h1>369</h1>
                  <h4>
                    {" "}
                    The world is like a game <br />
                  </h4>
                  Life is like a level of this game, there are a few hard levels
                  but eventually we get through them. <br /> Always remember
                  that there is no failure in challenges, you only fail when you
                  give up.
                  <br />
                  The 369 community members come from different countries, speak
                  multiple languages and has been blessed with different
                  abilities, talents and capabilities...
                  <br />
                  Membership in this community does not depend on the usual
                  methods, they are selected based on their commitment and
                  talents.
                  <br />
                  <br />
                  Increase your score by finding the keys and completing
                  challenges.
                  <br />
                  Your score shows your commitment and competence and it will be
                  very valuable to you.
                  <br />
                  <br />
                  Do not forget how successful we are in achieving our goals
                  depends on the performance of each member, so help each other
                  and introduce the 369 community to your talented friends.
                  <br />
                  <br />
                  <h4>Follow the Signs</h4>
                  <br />
                  <h3>
                    We See You
                    <br />
                    We Are 369
                    <br />
                  </h3>
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About;
