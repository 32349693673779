import React, { Component } from "react";
import ClaimPoint from "./ClaimPoint";
import DailyQuests from "./DailyQuests";
import QuestsNotifications from "./QuestsNotifications";
import OnceQuests from "./OnceQuests";
import WeeklyQuests from "./WeeklyQuests";
import StaticSpecial from "./StaticSpecial";
class Quests extends Component {
  state = {
    daily: false,
    weekly: false,
    once: false,
    claimPoint: false,
    notifications: false,
    public: true,
    staticSpecial: false,
  };
  refreshState = () => {
    this.setState({
      daily: false,
      weekly: false,
      once: false,
      claimPoint: false,
      notifications: false,
      public: false,
      staticSpecial: false,
    });
  };
  handlePublic = () => {
    this.refreshState();
    this.setState({ public: true });
  };
  handleDaily = () => {
    this.handlePublic();
    this.setState({ daily: true });
  };
  handleWeekly = () => {
    this.handlePublic();
    this.setState({ weekly: true });
  };
  handleSpecial = () => {
    this.handlePublic();
    this.setState({ once: true });
  };
  handleClaimPoint = () => {
    this.refreshState();
    this.setState({ claimPoint: true });
  };
  handleStaticSpecial = () => {
    this.refreshState();
    this.setState({ staticSpecial: true });
  };
  handleNotifications = () => {
    this.refreshState();
    this.setState({ notifications: true });
  };

  render() {
    const WQ = this.props.weeklyQuestsPass;
    return (
      <>
        <button
          id="update-btn"
          className="d-none"
          onClick={this.props.updateUser}
        ></button>

        <div className="min-vh-100 work bg-black">
          <div className="d-flex justify-content-center align-self-center min-h-work">
            <div className="row align-self-center g-1 w-100 h-100">
              <div className="col-12 col-md-2 d-flex flex-md-column min-h-quest-menu">
                <span
                  onClick={
                    !this.state.public ? this.handlePublic : this.handlePublic
                  }
                  className={
                    this.state.public
                      ? "mt-3 px-2 w-fit c-pointer  btn-scale  nav-active"
                      : "mt-3 border border-1 border-white px-2 w-fit BR-5 c-pointer btn-scale ms-2 ms-md-0 c-pointer"
                  }
                >
                  Social Media
                </span>
                <span
                  onClick={
                    !this.state.staticSpecial
                      ? this.handleStaticSpecial
                      : this.handlePublic
                  }
                  className={
                    this.state.staticSpecial
                      ? "mt-3 px-2 w-fit c-pointer  btn-scale  nav-active"
                      : "mt-3 border border-1 border-white px-2 w-fit BR-5 c-pointer btn-scale ms-2 ms-md-0 c-pointer"
                  }
                >
                  Special
                </span>
                {/* <span
                  onClick={
                    !this.state.once ? this.handleSpecial : this.refreshState
                  }
                  className={
                    this.state.once
                      ? "mt-3 px-2 w-fit c-pointer  btn-scale  nav-active"
                      : "mt-3 border border-1 border-white px-2 w-fit BR-5 c-pointer btn-scale ms-2 ms-md-0 c-pointer"
                  }
                >
                  Spesial
                </span> */}
                <span
                  onClick={
                    !this.state.claimPoint
                      ? this.handleClaimPoint
                      : this.handlePublic
                  }
                  className={
                    this.state.claimPoint
                      ? "mt-3 px-2 w-fit c-pointer  btn-scale  nav-active"
                      : "mt-3 border border-1 border-white px-2 w-fit BR-5 c-pointer btn-scale ms-2 ms-md-0 c-pointer"
                  }
                >
                  Claim Points
                </span>

                <span
                  onClick={
                    !this.state.notifications
                      ? this.handleNotifications
                      : this.handlePublic
                  }
                  className={
                    this.state.notifications
                      ? "mt-auto ms-auto ms-md-0 mb-0 mb-md-4 w-fit c-pointer  btn-scale  nav-active"
                      : "mt-auto ms-auto ms-md-0 mb-0 mb-md-4 border border-1 border-white px-2 w-fit BR-5 c-pointer btn-scale ms-2 ms-md-0 c-pointer"
                  }
                >
                  <i className="bi bi-bell fs-6"></i>Notifications
                </span>
              </div>
              <div className="col-12 col-md-10 px-2 quests pt-3">
                <div className="row align-self-center g-1 w-100 g-2">
                  {!this.state.daily &&
                  !this.state.weekly &&
                  !this.state.once &&
                  !this.state.notifications &&
                  !this.state.claimPoint &&
                  this.state.public ? (
                    <>
                      <div className="col-12 d-flex  mb-4">
                        <div
                          onClick={
                            !this.state.daily
                              ? this.handleDaily
                              : this.handlePublic
                          }
                          className={
                            this.state.daily
                              ? " px-2 mx-2 border border-1 BR-5 tag-active bg-dark text-success c-pointer"
                              : " px-2 mx-2 border border-1 BR-5  text-success c-pointer"
                          }
                        >
                          #DAILY
                        </div>
                        <div
                          onClick={
                            !this.state.weekly
                              ? this.handleWeekly
                              : this.handlePublic
                          }
                          className={
                            this.state.weekly
                              ? " px-2 mx-2 border border-1 BR-5 tag-active bg-dark text-info c-pointer"
                              : " px-2 mx-2 border border-1 BR-5  text-info c-pointer"
                          }
                        >
                          #WEEKLY
                        </div>
                        <div
                          onClick={
                            !this.state.once
                              ? this.handleSpecial
                              : this.handlePublic
                          }
                          className={
                            this.state.once
                              ? " px-2 mx-2 border border-1 BR-5 tag-active bg-dark text-warning c-pointer"
                              : " px-2 mx-2 border border-1 BR-5  text-warning c-pointer"
                          }
                        >
                          #ONCE
                        </div>
                      </div>
                      <div
                        onClick={
                          !this.state.daily
                            ? this.handleDaily
                            : this.handlePublic
                        }
                        className="col-12 text-success c-pointer"
                      >
                        #DAILY
                      </div>
                      <DailyQuests
                        DailyQuestsList={this.props.dailyQuests}
                        DQpass={this.props.dailyQuestsPass}
                        akuCount={this.props.akuCount}
                        ukaCount={this.props.ukaCount}
                        nftCount={this.props.akuCount + this.props.ukaCount}
                        user={this.props.user}
                        wallet={this.props.wallet}
                        refreshQuests={this.props.refreshQuests}
                        updateuser={this.props.updateUser}
                      />
                      <div
                        onClick={
                          !this.state.weekly
                            ? this.handleWeekly
                            : this.handlePublic
                        }
                        className="col-12 text-info c-pointer"
                      >
                        #WEEKLY
                      </div>
                      <WeeklyQuests
                        WeeklyQuestsList={this.props.weeklyQuests}
                        WQpass={WQ}
                        akuCount={this.props.akuCount}
                        ukaCount={this.props.ukaCount}
                        nftCount={this.props.akuCount + this.props.ukaCount}
                        user={this.props.user}
                        wallet={this.props.wallet}
                        refreshQuests={this.props.refreshQuests}
                        updateuser={this.props.updateUser}
                      />
                      <div
                        onClick={
                          !this.state.once
                            ? this.handleSpecial
                            : this.handlePublic
                        }
                        className="col-12 text-warning c-pointer"
                      >
                        #ONCE
                      </div>
                      <OnceQuests
                        specialQuestsList={this.props.specialQuests}
                        SQpass={this.props.specialQuestsPass}
                        akuCount={this.props.akuCount}
                        ukaCount={this.props.ukaCount}
                        nftCount={this.props.akuCount + this.props.ukaCount}
                        user={this.props.user}
                        wallet={this.props.wallet}
                        refreshQuests={this.props.refreshQuests}
                        updateuser={this.props.updateUser}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.daily ? (
                    <>
                      <div className="col-12 d-flex mb-4">
                        <div
                          onClick={
                            !this.state.daily
                              ? this.handleDaily
                              : this.handlePublic
                          }
                          className={
                            this.state.daily
                              ? " px-2 mx-2 border border-1 BR-5 tag-active bg-dark text-success c-pointer"
                              : " px-2 mx-2 border border-1 BR-5  text-success c-pointer"
                          }
                        >
                          #DAILY
                        </div>
                        <div
                          onClick={
                            !this.state.weekly
                              ? this.handleWeekly
                              : this.handlePublic
                          }
                          className={
                            this.state.weekly
                              ? " px-2 mx-2 border border-1 BR-5 tag-active bg-dark text-info c-pointer"
                              : " px-2 mx-2 border border-1 BR-5  text-info c-pointer"
                          }
                        >
                          #WEEKLY
                        </div>
                        <div
                          onClick={
                            !this.state.once
                              ? this.handleSpecial
                              : this.handlePublic
                          }
                          className={
                            this.state.once
                              ? " px-2 mx-2 border border-1 BR-5 tag-active bg-dark text-warning c-pointer"
                              : " px-2 mx-2 border border-1 BR-5  text-warning c-pointer"
                          }
                        >
                          #ONCE
                        </div>
                      </div>
                      <div
                        onClick={
                          !this.state.daily
                            ? this.handleDaily
                            : this.handlePublic
                        }
                        className="col-12 text-success c-pointer"
                      >
                        #DAILY
                      </div>
                      <DailyQuests
                        DailyQuestsList={this.props.dailyQuests}
                        DQpass={this.props.dailyQuestsPass}
                        akuCount={this.props.akuCount}
                        ukaCount={this.props.ukaCount}
                        nftCount={this.props.akuCount + this.props.ukaCount}
                        user={this.props.user}
                        wallet={this.props.wallet}
                        refreshQuests={this.props.refreshQuests}
                        updateuser={this.props.updateUser}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.weekly ? (
                    <>
                      <div className="col-12 d-flex mb-4">
                        <div
                          onClick={
                            !this.state.daily
                              ? this.handleDaily
                              : this.handlePublic
                          }
                          className={
                            this.state.daily
                              ? " px-2 mx-2 border border-1 BR-5 tag-active bg-dark text-success c-pointer"
                              : " px-2 mx-2 border border-1 BR-5  text-success c-pointer"
                          }
                        >
                          #DAILY
                        </div>
                        <div
                          onClick={
                            !this.state.weekly
                              ? this.handleWeekly
                              : this.handlePublic
                          }
                          className={
                            this.state.weekly
                              ? " px-2 mx-2 border border-1 BR-5 tag-active bg-dark text-info c-pointer"
                              : " px-2 mx-2 border border-1 BR-5  text-info c-pointer"
                          }
                        >
                          #WEEKLY
                        </div>
                        <div
                          onClick={
                            !this.state.once
                              ? this.handleSpecial
                              : this.handlePublic
                          }
                          className={
                            this.state.once
                              ? " px-2 mx-2 border border-1 BR-5 tag-active bg-dark text-warning c-pointer"
                              : " px-2 mx-2 border border-1 BR-5  text-warning c-pointer"
                          }
                        >
                          #ONCE
                        </div>
                      </div>
                      <div
                        onClick={
                          !this.state.weekly
                            ? this.handleWeekly
                            : this.handlePublic
                        }
                        className="col-12 text-info c-pointer"
                      >
                        #WEEKLY
                      </div>
                      <WeeklyQuests
                        WeeklyQuestsList={this.props.weeklyQuests}
                        WQpass={WQ}
                        akuCount={this.props.akuCount}
                        ukaCount={this.props.ukaCount}
                        nftCount={this.props.akuCount + this.props.ukaCount}
                        user={this.props.user}
                        wallet={this.props.wallet}
                        refreshQuests={this.props.refreshQuests}
                        updateuser={this.props.updateUser}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.once ? (
                    <>
                      <div className="col-12 d-flex mb-4">
                        <div
                          onClick={
                            !this.state.daily
                              ? this.handleDaily
                              : this.handlePublic
                          }
                          className={
                            this.state.daily
                              ? " px-2 mx-2 border border-1 BR-5 tag-active bg-dark text-success c-pointer"
                              : " px-2 mx-2 border border-1 BR-5  text-success c-pointer"
                          }
                        >
                          #DAILY
                        </div>
                        <div
                          onClick={
                            !this.state.weekly
                              ? this.handleWeekly
                              : this.handlePublic
                          }
                          className={
                            this.state.weekly
                              ? " px-2 mx-2 border border-1 BR-5 tag-active bg-dark text-info c-pointer"
                              : " px-2 mx-2 border border-1 BR-5  text-info c-pointer"
                          }
                        >
                          #WEEKLY
                        </div>
                        <div
                          onClick={
                            !this.state.once
                              ? this.handleSpecial
                              : this.handlePublic
                          }
                          className={
                            this.state.once
                              ? " px-2 mx-2 border border-1 BR-5 tag-active bg-dark text-warning c-pointer"
                              : " px-2 mx-2 border border-1 BR-5  text-warning c-pointer"
                          }
                        >
                          #ONCE
                        </div>
                      </div>
                      <div
                        onClick={
                          !this.state.once
                            ? this.handleSpecial
                            : this.handlePublic
                        }
                        className="col-12 text-warning c-pointer"
                      >
                        #ONCE
                      </div>
                      <OnceQuests
                        specialQuestsList={this.props.specialQuests}
                        SQpass={this.props.specialQuestsPass}
                        akuCount={this.props.akuCount}
                        ukaCount={this.props.ukaCount}
                        nftCount={this.props.akuCount + this.props.ukaCount}
                        user={this.props.user}
                        wallet={this.props.wallet}
                        refreshQuests={this.props.refreshQuests}
                        updateuser={this.props.updateUser}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.claimPoint ? (
                    <>
                      <ClaimPoint
                        refreshQuests={this.props.refreshQuests}
                        updateuser={this.props.updateUser}
                        user={this.props.user}
                        wallet={this.props.wallet}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.notifications ? (
                    <>
                      <QuestsNotifications
                        dailyQuestNotif={this.props.dailyQuestNotif}
                        weeklyQuestNotif={this.props.weeklyQuestNotif}
                        specialQuestNotif={this.props.specialQuestNotif}
                        notif={this.props.notif}
                        missionNotif={this.props.missionNotif}
                        updateuser={this.props.updateUser}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.staticSpecial ? (
                    <>
                      <StaticSpecial
                        akuCount={this.props.akuCount}
                        ukaCount={this.props.ukaCount}
                        nftCount={this.props.akuCount + this.props.ukaCount}
                        user={this.props.user}
                        wallet={this.props.wallet}
                        refreshQuests={this.props.refreshQuests}
                        updateuser={this.props.updateUser}
                        missionPass={this.props.missionPass}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Quests;
