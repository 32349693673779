import React, { Component } from "react";
import splashvideowebm from "../images/video/logo.webm";
import splashvideomp4 from "../images/video/logo.mp4";

class Splash extends Component {
  state = {};
  goto = () => {
    if (document.getElementById("end") !== null) {
      document.getElementById("end").click();
    }
  };
  render() {
    setTimeout(this.goto, 6000);
    return (
      <div className="container-fluid ">
        <button id="end" onClick={this.props.onEnd} className="d-none"></button>
        <div className="bg-black justify-content-center d-flex align-items-center h-100vh ">
          {/* <img className="max-w-100" src={splashGif} alt="" /> */}
          <video
            onEnded={this.props.onEnd}
            id="vid"
            className="w-75 op-8"
            autoPlay
            muted
            playsInline
          >
            <source src={splashvideowebm} type="video/webm" />
            <source src={splashvideomp4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  }
}

export default Splash;
