import React, { Component } from "react";
import SignUpForm from "./SignUpForm";
class Signup extends Component {
  state = {
    allowSignup: false,
    invite: {},
    code: null,
  };
  waitingBTN = (id) => {
    const bt = document.getElementById(id);
    bt.classList.add("disable-link");
    bt.innerHTML = "wait...";
  };
  unWaitingBTN = (id, o) => {
    const bt = document.getElementById(id);
    bt.classList.remove("disable-link");
    bt.innerHTML = o;
  };
  error = (id, m) => {
    const e = document.getElementById(id);
    e.innerHTML = m;
  };
  suc = (id, m) => {
    const s = document.getElementById(id);
    s.innerHTML = m;
  };
  setInput = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      invite: {
        ...prevState.invite,
        [name]: value,
      },
    }));
  };
  handleSignUpWithAku = () => {
    this.setState({ allowSignup: true });
  };
  handleSignUpWithInviteCode = async () => {
    this.error("err", "");
    this.suc("suc", "");
    this.waitingBTN("s-btn");

    this.checkInviteCode(this.state.invite.code).then((res) => {
      JSON.stringify(res);
      if (res[0]) {
        if (res[0].status === "available") {
          this.setState({ code: res[0] });
          this.setState({ allowSignup: true });
        } else {
          this.error("err", "Expired code, Try again");
          this.unWaitingBTN("s-btn", "Check The Code");
        }
      } else {
        this.error("err", "Wrong code, Try again");
        this.unWaitingBTN("s-btn", "Check The Code");
      }
    });
    // this.setState({ allowSignup: true });
  };
  checkInviteCode = async (I) => {
    const get = await fetch("https://api.the369.xyz/checkInviteCode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        inviteCode: I,
      }),
    }).then((res) => res.json());
    return get;
  };
  updateUser = () => {
    document.getElementById("update-btn").click();
    // document.getElementById("home-btn").click();
  };
  render() {
    return (
      <>
        <button
          id="update-btn"
          className="d-none"
          onClick={this.props.updateUser}
        ></button>
        {/* <button
          id="home-btn"
          className="d-none"
          onClick={this.props.handleHomePage}
        ></button> */}
        <div className="min-vh-100 work d-flex justify-content-center align-self-center">
          <div className="min-h-work d-flex justify-content-center align-self-center w-100">
            <div className="row m-3 justify-content-evenly align-self-center w-100">
              <div className="col-12 mt-3 h-400 div-work d-flex justify-content-center max-w-992">
                {this.state.allowSignup ? (
                  <>
                    <SignUpForm
                      NFTcount={this.props.NFTCount}
                      code={this.state.code}
                      wallet={this.props.wallet}
                      updateUser={this.updateUser}
                    />
                  </>
                ) : (
                  <>
                    {this.props.NFTCount === 0 ? (
                      <>
                        <div className="row justify-content-evenly align-self-center max-w-500">
                          <div className="col-12 text-center fs-2 fw-bold">
                            Sign Up
                          </div>

                          <div className="col-12 text-start border border-1 border-white mt-2 BR-5 d-flex flex-column p-2 order-1">
                            <span className="fw-bold fs-5">
                              Sign up with invite code{" "}
                            </span>
                            <span>
                              <input
                                onChange={this.setInput}
                                name="code"
                                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white"
                                type="text"
                                placeholder="Enter Invite Code"
                              />
                            </span>
                            <span className="fs-6 text-success" id="suc"></span>
                            <span className="fs-6 text-danger" id="err"></span>
                            <button
                              id="s-btn"
                              onClick={this.handleSignUpWithInviteCode}
                              className="btn-s bg-green align-self-start ms-3"
                            >
                              Check The Code
                            </button>
                          </div>
                          <div className="col-12 text-start border border-1 border-white mt-2 BR-5 d-flex flex-column p-2 order-2">
                            <span className="fw-bold fs-5">
                              Holders sign up
                            </span>
                            <span>
                              <span className="fs-5 text-danger">X</span>
                              <span className="fs-6 align-text-bottom">
                                You don't have any AkuAku
                              </span>
                            </span>
                            <button className="btn-s bg-green btn-disable align-self-end">
                              SignUp
                            </button>
                          </div>

                          <div className="col-12 text-end mt-2 BR-5 p-3 order-3">
                            <button
                              onClick={this.props.handleHomePage}
                              className="btn-s bg-green"
                            >
                              Continue as a guest
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row justify-content-evenly align-self-center max-w-500">
                          <div className="col-12 text-center fs-2 fw-bold">
                            Sign Up
                          </div>

                          <div className="col-12 text-start border border-1 border-white mt-2 BR-5 d-flex flex-column p-2 order-2">
                            <span className="fw-bold fs-5">
                              Sign up with invite code{" "}
                            </span>
                            <span>
                              <input
                                className="form-control form-control-sm m-2 w-300 border-danger bg-dark text-white"
                                type="text"
                                placeholder="Enter Invite Code"
                                disabled
                              />
                            </span>
                            <button className="btn-s bg-green btn-disable align-self-start ms-3">
                              Check The Code
                            </button>
                          </div>

                          <div className="col-12 text-start border border-1 border-white mt-2 BR-5 d-flex flex-column p-2 order-1">
                            <span className="fw-bold fs-5">
                              Holders sign up
                            </span>
                            <span>
                              <i className="bi bi-check2 text-success fs-3"></i>
                              <span className="fs-6 align-text-bottom">
                                You have {this.props.NFTCount} AkuAku
                              </span>
                            </span>
                            <button
                              onClick={this.handleSignUpWithAku}
                              className="btn-s bg-green align-self-end"
                            >
                              SignUp
                            </button>
                          </div>
                          <div className="col-12 text-end mt-2 BR-5 p-3 order-3">
                            <button
                              onClick={this.props.handleHomePage}
                              className="btn-s bg-green"
                            >
                              Continue as a guest
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Signup;
