import React, { Component } from "react";
class MessageMenu extends Component {
 
  state = {};
  render() {
    return (
      <>
        <div className="col-12 col-md-2 d-flex flex-md-column">
          <span
            onClick={this.props.onInbox}
            className={
              this.props.inbox
                ? "mt-3 nav-active px-2 w-fit c-reg btn-scale disable-link"
                : "mt-3 border border-1 border-white px-2 w-fit BR-5 c-pointer btn-scale ms-2 ms-md-0"
            }
          >
            <i className="bi bi-box-arrow-in-down fs-5"></i>Inbox
          </span>

          <span
            onClick={this.props.onSent}
            className={
              this.props.sent
                ? "mt-3 nav-active px-2 w-fit c-reg btn-scale disable-link"
                : "mt-3 border border-1 border-white px-2 w-fit BR-5 c-pointer btn-scale ms-2 ms-md-0"
            }
          >
            <i className="bi bi-box-arrow-up fs-5"></i>
            Sent
          </span>

          <span
            onClick={this.props.onCompose}
            className={
              this.props.compose
                ? "mt-auto ms-auto ms-md-0 mb-1 nav-active px-2 w-fit c-reg btn-scale disable-link"
                : "mt-auto ms-auto ms-md-0 mb-1 border border-1 border-white px-2 w-fit BR-5 c-pointer btn-scale"
            }
          >
            Compose <i className="bi bi-send fs-6"></i>
          </span>
        </div>
      </>
    );
  }
}

export default MessageMenu;
