import React, { Component } from "react";
import UserReqHistory from "./UserReqHistory";
class Setting extends Component {
  state = {
    userDate: {
      username: this.props.user.username,
      Telegram_Id: this.props.user.Telegram_Id,
    },
    req: { new_twitter_id: "" },
  };
  requestTwitterChange = async (w, o, n) => {
    const get = await fetch("https://api.the369.xyz/twitterChangeReq", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        old_twitter_id: o,
        new_twitter_id: n,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  setInputReq = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      req: {
        ...prevState.req,
        [name]: value,
      },
    }));
  };
  handleChangeTwitter = async () => {
    this.error("req-err", "");
    this.suc("req-suc", "");
    this.waitingBTN("req-btn");
    if (this.state.req.new_twitter_id !== "") {
      const res = this.requestTwitterChange(
        this.props.wallet,
        this.props.user.Twitter_Id,
        this.checkId(this.state.req.new_twitter_id)
      );
      if (res) {
        this.suc(
          "req-suc",
          "Your request send successfully, wait for confirmation"
        );
        this.unWaitingBTN("req-btn", "Send Request");
        document.getElementById("new_twitter_id").value = "";
        document.getElementById("update-btn").click();
      } else {
        this.error("req-err", "Something wrong, try again later");
        this.unWaitingBTN("req-btn", "Send Request");
      }
    } else {
      this.unWaitingBTN("req-btn", "Send Request");
    }
  };
  setInput = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      userDate: {
        ...prevState.userDate,
        [name]: value,
      },
    }));
  };
  checkUsername = (u) => {
    const l = u.length;
    if (l > 15) {
      return "Username max characters is 15";
    } else {
      if (l < 2) {
        return "Username must be at least 2 characters long ";
      } else {
        if (u.replaceAll(" ", "") === "369") {
          return "Username can't be: 369 ";
        } else {
          return "ok";
        }
      }
    }
  };
  checkId = (i) => {
    var temp = i.split("");
    if (temp[0] === "@") {
      return i;
    } else {
      return `@${i}`;
    }
  };
  waitingBTN = (id) => {
    const bt = document.getElementById(id);
    bt.classList.add("disable-link");
    bt.innerHTML = "wait...";
  };
  unWaitingBTN = (id, o) => {
    const bt = document.getElementById(id);
    bt.classList.remove("disable-link");
    bt.innerHTML = o;
  };
  error = (id, m) => {
    const e = document.getElementById(id);
    e.innerHTML = m;
  };
  suc = (id, m) => {
    const s = document.getElementById(id);
    s.innerHTML = m;
  };
  refreshForm = () => {
    const username = document.getElementById("username");
    const Telegram_Id = document.getElementById("Telegram_Id");
    username.value = "";
    Telegram_Id.value = "";
  };
  handleUpdateUserData = async () => {
    this.suc("suc", "");
    this.error("err", "");
    this.waitingBTN("btn");
    if (this.checkUsername(this.state.userDate.username) === "ok") {
      if (
        this.state.userDate.username === this.props.user.username &&
        this.state.userDate.Telegram_Id === this.props.user.Telegram_Id
      ) {
        this.unWaitingBTN("btn", "Save");
      } else {
        const res = await this.updateUserData(
          this.props.wallet,
          this.state.userDate.username,
          this.checkId(this.state.userDate.Telegram_Id)
        );
        if (res) {
          this.suc("suc", "Your information changed successfully");
          document.getElementById("update-btn").click();
          this.refreshForm();
          this.unWaitingBTN("btn", "Save");
        }
      }
    } else {
      this.error("err", this.checkUsername(this.state.userDate.username));
      this.unWaitingBTN("btn", "Save");
    }
  };
  updateUserData = async (w, u, T) => {
    const get = await fetch("https://api.the369.xyz/updateUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        username: u,
        Telegram_Id: T,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  updateProfilePic = async (w, i) => {
    const get = await fetch("https://api.the369.xyz/updateProfilePic", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        profilepic_id: i,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("update-btn").click();
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  render() {
    return (
      <>
        <button
          id="update-btn"
          className="d-none"
          onClick={this.props.updateUser}
        ></button>
        <div className="min-vh-100 work d-flex justify-content-center align-self-center">
          <div className="min-h-work d-flex justify-content-center align-self-center w-100">
            <div className="row m-3 justify-content-evenly align-self-center w-100">
              <div className="col-12 mt-3 h-400 div-work d-flex justify-content-center max-w-992">
                <div className="row justify-content-evenly g-1 w-100 ">
                  <div className="col-12 fs-4 fw-bolder text-center max-h-56 position-relative ">
                    Setting
                    <span className="position-absolute top-0 start-100 translate-middle d-flex flex-column mt-2">
                      <button
                        onClick={this.props.disconnect}
                        className="nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent "
                        aria-current="page"
                      >
                        <i className="fs-3 bi-door-open btn-369 bg-dark navbar-item-custom text-danger border border-1 border-light"></i>
                      </button>
                      <span className="text-muted fs-8">LogOut</span>
                    </span>
                    {this.props.forceSetting ? (
                      <>
                        <br />
                        <span className="fs-5 text-danger">
                          {" "}
                          Review your information
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-12 col-md-6   p-3">
                    <div className="d-flex flex-column border border-1 p-3">
                      {/* <span className="">Informations</span> */}
                      <div className="p-1 text-center profile-pic-list">
                        <img
                          alt="AKU PROFILE"
                          onClick={() => {
                            this.updateProfilePic(this.props.wallet, 0);
                          }}
                          className={
                            this.props.user.profilepic_id === 0
                              ? "profile-pic-setting-active me-2"
                              : "profile-pic-setting me-2"
                          }
                          width="100"
                          height="100"
                          src={`./NFTImages/0.jpg`}
                        />
                        {this.props.userTokenList.map((token, index) => {
                          return (
                            <img
                              key={index}
                              alt="AKU PROFILE"
                              onClick={() => {
                                this.updateProfilePic(this.props.wallet, token);
                              }}
                              className={
                                this.props.user.profilepic_id === token
                                  ? "profile-pic-setting-active me-2"
                                  : "profile-pic-setting me-2"
                              }
                              width="100"
                              height="100"
                              src={`./NFTImages/${token}.jpg`}
                            />
                          );
                        })}
                      </div>

                      <input
                        onChange={this.setInput}
                        id="username"
                        className="form-control form-control-sm m-2 max-w-300 border-success bg-dark text-white"
                        type="text"
                        name="username"
                        placeholder={`Username: ${this.props.user.username}`}
                      />
                      <input
                        onChange={this.setInput}
                        id="Telegram_Id"
                        name="Telegram_Id"
                        className="form-control form-control-sm m-2 max-w-300 border-success bg-dark text-white"
                        type="text"
                        placeholder={`Telegram ID: ${this.props.user.Telegram_Id}`}
                      />
                      <span className="fs-6 text-success" id="suc"></span>
                      <span className="fs-6 text-danger" id="err"></span>
                      <button
                        id="btn"
                        onClick={this.handleUpdateUserData}
                        className="btn-s bg-green align-self-start ms-3 mt-3 fs-5"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-6    p-3">
                    <div className="d-flex flex-column border border-1 p-3 h-100">
                      {/* <span className="text-center">Requests</span> */}
                      <span className="mt-5">Change Twitter ID</span>
                      <input
                        onChange={this.setInputReq}
                        name="new_twitter_id"
                        id="new_twitter_id"
                        className="form-control form-control-sm m-2 max-w-300 border-success bg-dark text-white"
                        type="text"
                        placeholder={`Twitter ID: ${this.props.user.Twitter_Id}`}
                      />
                      <span className="fs-6 text-success" id="req-suc"></span>
                      <span className="fs-6 text-danger" id="req-err"></span>
                      <button
                        id="req-btn"
                        onClick={this.handleChangeTwitter}
                        className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 mt-auto"
                      >
                        Send Request
                      </button>
                      {this.props.userReqHistory.length > 0 ? (
                        <>
                          <UserReqHistory history={this.props.userReqHistory} />{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Setting;
