import React, { Component } from "react";
class UserReqHistory extends Component {
  state = {};
  timeGenarator = (t) => {
    // eslint-disable-next-line
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    // const [H, M, S] = time.split(":");
    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  render() {
    return (
      <>
        <div className=" history w-100 mt-2 p-2 d-flex flex-column">
          <div className="history-item fs-7 d-flex justify-content-evenly ">
            <span className=" fs-8 history-item-child text-center w-100 ">
              From
            </span>
            <span className=" fs-8 history-item-child text-center w-100">
              To
            </span>
            <span className="fs-8 history-item-child text-center  w-100">
              Status
            </span>

            <span className="fs-8 text-muted w-100 text-center">Date</span>
          </div>
          <div
            key={this.props.history[0].TCR_ID}
            className="history-item fs-7 d-flex justify-content-evenly  align-items-center "
          >
            <span className="history-item-child text-center w-100 ">
              {this.props.history[0].old_twitter_id}
            </span>
            <span className="history-item-child text-center w-100">
              {this.props.history[0].new_twitter_id}
            </span>
            <span
              className={
                this.props.history[0].status === "pending"
                  ? "history-item-child text-center text-warning w-100"
                  : this.props.history[0].status === "confirmed"
                  ? "history-item-child text-center text-success w-100"
                  : "history-item-child text-center text-danger w-100"
              }
            >
              {this.props.history[0].status}
            </span>

            <span className="fs-8 text-muted w-100 text-center">
              {this.timeGenarator(this.props.history[0].Time)}
            </span>
          </div>
          {/* {this.props.history.map((item) => {
            return (
              
                <div key={item.TCR_ID} className="history-item fs-7 d-flex justify-content-evenly  align-items-center ">
                  <span className="history-item-child text-center w-100 ">
                    {item.old_twitter_id}
                  </span>
                  <span className="history-item-child text-center w-100">
                    {item.new_twitter_id}
                  </span>
                  <span
                    className={
                      item.status === "pending"
                        ? "history-item-child text-center text-warning w-100"
                        : item.status === "confirmed"
                        ? "history-item-child text-center text-success w-100"
                        : "history-item-child text-center text-danger w-100"
                    }
                  >
                    {item.status}
                  </span>
                  <span className="history-item-child text-center w-100">
                    {" "}
                    {item.message}
                  </span>{" "}
                  <span className="fs-8 text-muted w-100 text-center">
                    {this.timeGenarator(item.Time)}
                  </span>
                </div>
             
            );
          })} */}
        </div>
      </>
    );
  }
}

export default UserReqHistory;
