import React, { Component } from "react";

import SpecialOne from "./Specials/SpecialOne";
import SpecialTwo from "./Specials/SpecialTwo";
import SpecialThree from "./Specials/SpecialThree";
// import SpecialTwo from "./Specials/SpecialTwo";

class StaticSpecial extends Component {
  state = {};
  render() {
    console.log("mission pass : ", this.props.missionPass);
    return (
      <>
        {(() => {
          if (true) {
            return (
              <>
                {/* <SpecialOne
                  refreshQuests={this.props.refreshQuests}
                  updateuser={this.props.updateUser}
                  user={this.props.user}
                  missionPass={this.props.missionPass}
                />
                <SpecialTwo
                  refreshQuests={this.props.refreshQuests}
                  updateuser={this.props.updateUser}
                  user={this.props.user}
                  missionPass={this.props.missionPass}
                /> */}
                <SpecialOne
                  refreshQuests={this.props.refreshQuests}
                  updateuser={this.props.updateUser}
                  user={this.props.user}
                  missionPass={this.props.missionPass}
                />
                <SpecialTwo
                  refreshQuests={this.props.refreshQuests}
                  updateuser={this.props.updateUser}
                  user={this.props.user}
                  missionPass={this.props.missionPass}
                />
                <SpecialThree
                  refreshQuests={this.props.refreshQuests}
                  updateuser={this.props.updateUser}
                  user={this.props.user}
                  missionPass={this.props.missionPass}
                />
              </>
            );
          }
        })()}
      </>
    );
  }
}

export default StaticSpecial;
