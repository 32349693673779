import React, { Component } from "react";

class Loading extends Component {
 
  state = {};
  render() {
    return (
      <>
        <div className="container-fluid p-0">
          <div className="bg-black justify-content-center d-flex align-items-center h-100vh ">
            <h1 className="loading-custom text-center">
              <span className="loading-in let1">l</span>
              <span className="loading-in let2">o</span>
              <span className="loading-in let3">a</span>
              <span className="loading-in let4">d</span>
              <span className="loading-in let5">i</span>
              <span className="loading-in let6">n</span>
              <span className="loading-in let7">g</span>
              <span className="loading-in let8">.</span>
              <span className="loading-in let9">.</span>
              <span className="loading-in let10">.</span>
              {this.props.delay ? (
                <>
                  <br />
                  <br />

                  <span className="fs-6 text-muted loading-delay">
                    This might take a few minutes
                  </span>
                </>
              ) : (
                <></>
              )}
            </h1>
          </div>
        </div>
        {/* <div className="min-vh-100 work">
          <div className="d-flex justify-content-center align-self-center min-h-work">
            <div className="preloader-1">
              <div className="loading">Loading</div>
              <span className="line line-1"></span>
              <span className="line line-2"></span>
              <span className="line line-3"></span>
              <span className="line line-4"></span>
              <span className="line line-5"></span>
              <span className="line line-6"></span>
              <span className="line line-7"></span>
              <span className="line line-8"></span>
              <span className="line line-9"></span>
            </div>
            {this.props.delay ? (
              <>
                <br />
                <br />

                <span className="fs-6 text-muted">
                  This might take a few minutes
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
        </div> */}
      </>
    );
  }
}

export default Loading;
