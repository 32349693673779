import React, { Component } from "react";

class SpecialNotifItem extends Component {
  state = {};
  path = "https://api.the369.xyz/images/";
  timeGenarator = (t) => {
    // eslint-disable-next-line
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    // const [H, M, S] = time.split(":");
    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  render() {
    return (
      <>
        <div
          key={this.props.k}
          className="col-12  d-flex justify-content-start  align-self-center"
        >
          <div className="bg-dark notification d-flex flex-column ms-4 w-100 position-relative pb-36">
            {/* <span className="fs-7 fw-bold text-success position-absolute top-0 end-0 me-2 mt-2">
              <i className="bi bi-bell fs-4 text-warning"></i>
            </span> */}
            {this.props.item.status === "passed" ? (
              <>
                <span className="fs-8 fw-bold text-white bg-success px-1 mt-2 position-absolute top-0 end-0 me-2 mb-2">
                  SUCCESS
                </span>
              </>
            ) : (
              <></>
            )}
            {this.props.item.status === "pending" ? (
              <>
                <span className="fs-8 fw-bold text-white bg-warning px-1 mt-2 position-absolute top-0 end-0 me-2 mb-2">
                  IN REVIEW
                </span>
              </>
            ) : (
              <></>
            )}
            {this.props.item.status === "fail" ? (
              <>
                <span className="fs-8 fw-bold text-white bg-danger px-1 mt-2 position-absolute top-0 end-0 me-2 mb-2">
                  FAIL
                </span>
              </>
            ) : (
              <></>
            )}
            {/* <span className="fs-8 fw-bold px-1 mt-2 position-absolute top-0 end-0 me-2 mb-2">
              <i className="bi bi-bell fs-4 text-warning"></i>
            </span> */}
            <span className="fw-bolder fs-3">
              {this.props.item.subject}
              <span className="fs-8 text-muted ms-2">
                {this.timeGenarator(this.props.item.Time)}
              </span>
            </span>
            {this.props.item.answer_type !== null ? (
              <>
                <div className="accordion " id={`moreNotifBase${this.props.k}`}>
                  <div className="accordion-item border border-0 ">
                    <h2
                      className="accordion-header "
                      id="panelsStayOpen-headingTwo"
                    >
                      <button
                        className="accordion-button bg-dark text-white collapsed border border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#moreNotif${this.props.k}`}
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        Show more
                      </button>
                    </h2>
                    <div
                      id={`moreNotif${this.props.k}`}
                      className="accordion-collapse collapse bg-dark"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body d-flex flex-column">
                        <>
                          <span className="text-white fs-5">
                            <span className="fw-bolder text-muted">
                              Mission:
                            </span>{" "}
                            {this.props.item.message}
                          </span>
                          {this.props.item.answer_type === "link" ? (
                            <>
                              <span>
                                <a
                                  rel="noopener noreferrer"
                                  href={this.props.item.answer}
                                  target="_blank"
                                  className="not-underline fs-7 text-muted"
                                >
                                  {`${this.props.item.answer.substring(
                                    0,
                                    25
                                  )}...`}
                                  <i className="bi bi-box-arrow-up-right ms-3 text-light"></i>
                                </a>
                              </span>
                              {this.props.item.msg !== null ? (
                                <>
                                  <span className="text-white">
                                    {" "}
                                    {this.props.item.msg}
                                    {"  "}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                              {this.props.item.review_Time !== null ? (
                                <>
                                  <span className="fs-8  p-1 text-light w-fit mb-2">
                                    Review in:{" "}
                                    {this.timeGenarator(
                                      this.props.item.review_Time
                                    )}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {this.props.item.answer_type === "img" ? (
                            <>
                              <span>
                                <img
                                  alt="AKU ANSWER"
                                  className="me-2 answer-image border-light"
                                  src={`${this.path}${this.props.item.answer}`}
                                />
                              </span>
                              {this.props.item.msg !== null ? (
                                <>
                                  <span className="text-light">
                                    {" "}
                                    {this.props.item.msg}{" "}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                              {this.props.item.review_Time !== null ? (
                                <>
                                  {" "}
                                  <span className="fs-8  p-1 text-light w-fit mb-2">
                                    Review in:{" "}
                                    {this.timeGenarator(
                                      this.props.item.review_Time
                                    )}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {this.props.item.answer_type === "text" ? (
                            <>
                              <span className="text-light">
                                Answer: {this.props.item.answer}
                              </span>
                              {this.props.item.msg !== null ? (
                                <>
                                  <span className="text-light">
                                    {" "}
                                    {this.props.item.msg}{" "}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                              {this.props.item.review_Time !== null ? (
                                <>
                                  {" "}
                                  <span className="fs-8  p-1 text-light w-fit mb-2">
                                    Review in:{" "}
                                    {this.timeGenarator(
                                      this.props.item.review_Time
                                    )}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {this.props.item.status === "passed" ? (
              <>
                {this.props.item.xp !== 0 || this.props.item.score !== 0 ? (
                  <>
                    <span className="reward-notif d-flex justify-content-between">
                      <span className="fw-bolder align-self-center">
                        Rewards:
                      </span>{" "}
                      {this.props.item.xp !== 0 ? (
                        <>
                          <span className="ms-2 p-1 bg-dark rounded">
                            {this.props.item.xp}{" "}
                            <img
                              alt="AKU XP"
                              className=""
                              width="20"
                              height="20"
                              src="./asset/Menu/xp.png"
                            />{" "}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.item.score !== 0 ? (
                        <>
                          {this.props.item.score === 1 ? (
                            <>
                              <span className="ms-2 p-1 bg-dark rounded text-success fw-bold fs-7">
                                {this.props.item.score} POINT
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="ms-2 p-1 bg-dark rounded text-success fw-bold fs-7">
                                {this.props.item.score} POINTS
                              </span>
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            <span className="fs-8 fw-bold text-danger bg-dark px-1 mt-2 position-absolute bottom-0 end-0 me-2 mb-2">
              ONCE
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default SpecialNotifItem;
