import React, { Component } from "react";
import NotifItem from "./notif_Item/NotifItem";
import DailyNotifItem from "./notif_Item/DailyNotifItem";
import WeeklyNotifItem from "./notif_Item/WeeklyNotifItem";
import SpecialNotifItem from "./notif_Item/SpecialNotifItem";
import MissionNotifItem from "./notif_Item/MissionNotifItem";
class QuestsNotifications extends Component {
  state = {
    n: [],
  };
  componentDidMount() {
    var a = [];
    a.push(...this.props.dailyQuestNotif);
    a.push(...this.props.weeklyQuestNotif);
    a.push(...this.props.specialQuestNotif);
    a.push(...this.props.notif);
    a.push(...this.props.missionNotif);

    const sortByDate = (a) => {
      const sorter = (a, b) => {
        return new Date(b.Time).getTime() - new Date(a.Time).getTime();
      };
      a.sort(sorter);
    };
    sortByDate(a);

    this.setState({ n: a });
  }
  render() {
    return (
      <>
        {this.state.n.map((item, index) => {
          if (item.type === "notif") {
            return <NotifItem key={index} k={index} item={item} />;
          }
          if (item.type === "daily") {
            return <DailyNotifItem key={index} k={index} item={item} />;
          }
          if (item.type === "weekly") {
            return <WeeklyNotifItem key={index} k={index} item={item} />;
          }
          if (item.type === "special") {
            return <SpecialNotifItem key={index} k={index} item={item} />;
          }
          if (item.type === "mission") {
            return <MissionNotifItem key={index} k={index} item={item} />;
          }
          return null;
        })}
      </>
    );
  }
}

export default QuestsNotifications;
