import React, { Component } from "react";
import akuakump3 from "../music/akuaku.mp3";
import akuakuogg from "../music/akuaku.ogg";
// import divanomp3 from "../music/divano.mp3";
// import divanoogg from "../music/divano.ogg";

class BackgroundMusic extends Component {
  state = {
    currentMusic: "akuaku",
  };

  defvol = () => {
    // document
    //   .getElementById(this.state.currentMusic)
    //   .addEventListener("ended", () => {
    //     if (this.state.currentMusic === "akuaku") {
    //       this.setState({ currentMusic: "divano" });
    //     } else {
    //       this.setState({ currentMusic: "divano" });
    //     }
    //   });
    var audio = document.getElementById(this.state.currentMusic);
    audio.volume = 0.5;
  };
  play = () => {
    var audio = document.getElementById(this.state.currentMusic);
    var bt = document.getElementById("bt");
    if (audio.paused) {
      audio.play();

      bt.innerHTML = `
        <i class="fs-5 bi bi-pause-circle text-white nav-active  c-pointer p-0 "></i>
        `;
    } else {
      audio.pause();
      bt.innerHTML = `
        <i class="fs-5 bi bi-play-circle text-danger   c-pointer "></i>
        `;
    }
  };
  changeTrack = (c) => {
    var a;
    if (this.state.currentMusic === "akuaku") {
      if (!document.getElementById("akuaku").paused) {
        document.getElementById("akuaku").pause();
        document.getElementById("akuaku").currentTime = 0;
        a = document.getElementById("divano");
        a.play();
      }
      if (c) {
        this.setState({ currentMusic: "divano" });
        a = document.getElementById("divano");
        a.play();
      } else {
        this.setState({ currentMusic: "divano" });
      }
    } else {
      if (!document.getElementById("divano").paused) {
        document.getElementById("divano").pause();
        document.getElementById("divano").currentTime = 0;
        a = document.getElementById("akuaku");
        a.play();
      }
      if (c) {
        this.setState({ currentMusic: "akuaku" });
        a = document.getElementById("akuaku");
        a.play();
      } else {
        this.setState({ currentMusic: "akuaku" });
      }
    }
  };

  render() {
    return (
      <>
        <audio
          id="akuaku"
          onPlay={this.defvol}
          // onEnded={() => {
          //   this.changeTrack(true);
          // }}
          loop
        >
          <source src={akuakuogg} type="audio/ogg"></source>
          <source src={akuakump3} type="audio/mpeg"></source>
        </audio>
        {/* <audio
          id="divano"
          onPlay={this.defvol}
          onEnded={() => {
            this.changeTrack(true);
          }}
        >
          <source src={divanoogg} type="audio/ogg"></source>
          <source src={divanomp3} type="audio/mpeg"></source>
        </audio> */}
        <div className="sosial">
          <a className="not-underline" id="bt" href="/#" onClick={this.play}>
            <i className="fs-5 bi bi-play-circle text-danger  c-pointer"></i>
          </a>
          {/* <a
            className="not-underline"
            id="bt"
            href="/#"
            onClick={() => {
              this.changeTrack(false);
            }}
          >
            <i className="fs-5 bi-skip-end-circle text-white  c-pointer ms-2"></i>
          </a> */}
        </div>
      </>
    );
  }
}

export default BackgroundMusic;
