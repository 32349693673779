import React, { Component } from "react";
import Lcard from "./Lcard";
class Leaderboard extends Component {
  state = {};
  walletGenerator = (w) => {
    let newWallet = w.split("");

    let length = newWallet.length;

    let temp =
      newWallet[0] +
      newWallet[1] +
      newWallet[2] +
      newWallet[3] +
      newWallet[4] +
      "..." +
      newWallet[length - 4] +
      newWallet[length - 3] +
      newWallet[length - 2] +
      newWallet[length - 1];

    return temp;
  };
  target = () => {
    var target = document.getElementById("target");
    target.classList.remove("target-done");
    target.classList.add("target");
    setTimeout(() => {
      target.classList.add("target-done");
      target.classList.remove("target");
    }, 1500);
  };
  render() {
    return (
      <>
        <div className="min-vh-100 work d-flex justify-content-center align-self-center">
          <div className="min-h-work d-flex justify-content-center align-self-center w-100">
            <div className="row m-3 justify-content-evenly align-self-center w-100">
              <div className="col-12 mt-3 h-400 div-work d-flex justify-content-center max-w-992">
                <div className="row justify-content-evenly align-self-center g-1 w-100">
                  {this.props.isConnected && this.props.isMember ? (
                    <>
                      <button
                        className="btn btn-transparent border border-0"
                        onClick={this.target}
                      >
                        <a className="not-underline link-light" href="#target">
                          <div className="col-12 text-center border border-1 border-white p-2 BR-5 L-rank c-pointer">
                            <div className="row justify-content-evenly align-self-center g-1 w-100">
                              <div className="col-2 justify-content-center d-flex align-items-center">
                                <img
                                  alt="AKU PROFILE"
                                  className="profile-pic"
                                  width="75"
                                  height="75"
                                  src={`./NFTImages/${this.props.user.profilepic_id}.jpg`}
                                />
                              </div>
                              <div className="col-8 justify-content-center d-flex align-items-center">
                                <div className="d-flex flex-column flex-md-row justify-content-evenly align-self-center g-1 w-100">
                                  <div className="justify-content-center d-flex align-items-center">
                                    Rank #{this.props.rank}
                                  </div>
                                  <div className=" justify-content-center d-flex align-items-center ">
                                    {this.props.user.username}
                                  </div>
                                  <div className="justify-content-center d-flex align-items-center">
                                    {this.walletGenerator(
                                      this.props.user.wallet_address
                                    )}
                                  </div>
                                  <div className="justify-content-center d-flex align-items-center">
                                    <span className="badge text-bg-dark me-1 mt-2">
                                      <img
                                        alt="AKU XP"
                                        className=""
                                        width="20"
                                        height="20"
                                        src="./asset/Menu/xp.png"
                                      />{" "}
                                      <span className="fs-7 ms-1 fw-bold">
                                        {this.props.user.xp}
                                      </span>
                                    </span>
                                  </div>
                                  <div className="justify-content-center d-flex align-items-center text-success">
                                    Score {this.props.user.score}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="col-12 text-center fs-2 fw-bold">
                    Leaderboard
                  </div>

                  {this.props.users.map((item, index) => {
                    index++;
                    var target = false;
                    if (this.props.isConnected && this.props.isMember) {
                      if (
                        item.wallet_address.toLowerCase() ===
                        this.props.user.wallet_address.toLowerCase()
                      ) {
                        target = true;
                      }
                    }

                    return (
                      <Lcard
                        key={item.user_id}
                        aku={this.props.usersAkuCount[item.wallet_address]}
                        item={item}
                        itemRank={index}
                        target={target}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Leaderboard;
