import React, { Component } from "react";

class SpecialThreeItem extends Component {
  state = {};
  render() {
    return (
      <>
        <div className=" text-center row">
          <div className="ms-auto me-auto d-flex col-12 col-md-10 col-lg-8">
            {" "}
            <img
              alt="AKUAKU"
              className=" w-100"
              src="./asset/special/Three/Mission3.png"
            />
          </div>
        </div>
      </>
    );
  }
}

export default SpecialThreeItem;
